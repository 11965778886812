@import '/src/styles/imports.scss';
$current: $mobile;
.comment-wrapper {
    
}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .comment-wrapper {
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    .comment-wrapper {
        display: flex;
        align-items: center;
        border-radius: vw(30, $current);
        padding: vw(5, $current) vw(10, $current) vw(5, $current) vw(5, $current);
        box-shadow: 0 0 10px rgba(0,0,0,0.2);

        .comment-left-side {
            cursor: pointer;
            width: vw(40, $current);
            height: vw(40, $current);
            border-radius: 50%;
            overflow: hidden;
            margin-inline-end: vw(15, $current);

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .comment-header {
            display: flex;
            align-items: center;

            .sender-name {
                cursor: pointer;
                font-weight: bold;
                margin-inline-end: vw(5, $current);
                font-size: vw(14, $current);
            }

            .likes {
                font-size: vw(10, $current);
            }
        }

        .comment-content {
            font-size: vw(12, $current);
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .comment-wrapper {
        border-radius: vw(30, $current);
        padding: vw(5, $current) vw(10, $current) vw(5, $current) vw(5, $current);
        .comment-left-side {
            width: vw(40, $current);
            height: vw(40, $current);
            margin-inline-end: vw(15, $current);
        }

        .comment-header {

            .sender-name {
                margin-inline-end: vw(5, $current);
                font-size: vw(14, $current);
            }

            .likes {
                font-size: vw(10, $current);
            }
        }

        .comment-content {
            font-size: vw(12, $current);
        }
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .comment-wrapper {
        border-radius: vw(30, $current);
        padding: vw(5, $current) vw(10, $current) vw(5, $current) vw(5, $current);
        .comment-left-side {
            width: vw(40, $current);
            height: vw(40, $current);
            margin-inline-end: vw(15, $current);
        }

        .comment-header {

            .sender-name {
                margin-inline-end: vw(5, $current);
                font-size: vw(14, $current);
            }

            .likes {
                font-size: vw(10, $current);
            }
        }

        .comment-content {
            font-size: vw(12, $current);
        }
    }
}
@import '/src/styles/imports.scss';
$current: $mobile;
.cms-login-wrapper {
    .cms-login-fields-wrapper{
        border: 1px solid black;
        width: vw(300, $current);
        padding: vw(50, $current);
    }
}

@media (min-width: $tablet_px) {
    $current: $tablet;
    .cms-login-wrapper {
        .cms-login-fields-wrapper{
            width: vw(300, $current);
            padding: vw(50, $current);
        }
    }
}

@media (min-width: $desktop_px) {
    $current: $desktop;
    .cms-login-wrapper {
        .cms-login-fields-wrapper{
            width: vw(300, $current);
            padding: vw(50, $current);
        }
    }
}

@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .cms-login-wrapper {
        .cms-login-fields-wrapper{
            width: vw(300, $current);
            padding: vw(50, $current);
        }
    }
}

@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .cms-login-wrapper {
        .cms-login-fields-wrapper{
            width: vw(300, $current);
            padding: vw(50, $current);
        }
    }
}
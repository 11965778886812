@import '/src/styles/imports.scss';
$current: $desktop_max;

.cms-wrapper {
    position: relative;
    min-height: 100vh;

    .content {
        @include cms-padding;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        
        // table {
        //     width: 70%;
        // }

        .welcome-msg{
            text-align: center;
            line-height: 1.5;
            .title {
                font-family: 'Pacifico';
                font-size: vw(55, $current);
                color: #00B4B4;
            }

            .logout-btn {
                margin-top: vw(10, $current);
            }
            .login-btn {
                background-color: #00B4B4;
                padding: vw(10, $current) vw(20, $current);
                text-align: center;
                color: white;
                border-radius: vw(10, $current);
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .cms-footer { 
        position: absolute;
        bottom: vw(10, $current);
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .back-to-site-link {
            color: $color_primary;
            font-weight: bold;
            font-size: vw(12, $current);
        }

        .change-language {
            margin-top: vw(10, $current);
            font-size: vw(10, $current);
            font-weight: bold;
            cursor: pointer;
        }
    }
}

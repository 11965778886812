@import '/src/styles/imports.scss';
$current: $mobile;
.loader-rings {
    display: inline-block;
    position: relative;
    width: vw(80, $current);
    height: vw(80, $current);
    
    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: vw(64, $current);
        height: vw(64, $current);
        margin: vw(8, $current);
        border: vw(8, $current) solid $color_primary;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: $color_primary transparent transparent transparent;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }

        &:nth-child(2) {
            animation-delay: -0.3s;
        }

        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .loader-rings {
        width: vw(80, $current);
        height: vw(80, $current);
        div {
            width: vw(64, $current);
            height: vw(64, $current);
            margin: vw(8, $current);
            border: vw(8, $current) solid $color_primary;
            border-color: $color_primary transparent transparent transparent;
        }
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    .loader-rings {
        width: vw(80, $current);
        height: vw(80, $current);
        div {
            width: vw(64, $current);
            height: vw(64, $current);
            margin: vw(8, $current);
            border: vw(8, $current) solid $color_primary;
            border-color: $color_primary transparent transparent transparent;
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .loader-rings {
        width: vw(80, $current);
        height: vw(80, $current);
        div {
            width: vw(64, $current);
            height: vw(64, $current);
            margin: vw(8, $current);
            border: vw(8, $current) solid $color_primary;
            border-color: $color_primary transparent transparent transparent;
        }
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .loader-rings {
        width: vw(80, $current);
        height: vw(80, $current);
        div {
            width: vw(64, $current);
            height: vw(64, $current);
            margin: vw(8, $current);
            border: vw(8, $current) solid $color_primary;
            border-color: $color_primary transparent transparent transparent;
        }
    }
}


@keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
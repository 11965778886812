@import '/src/styles/imports.scss';

.translations-wrapper {
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .search-wrapper{
        width: fit-content;
        background-color: #ececec;
        border-radius: 20px;
        padding: vw(20, $desktop_max) vw(20, $desktop_max);
        display: flex;
        justify-content: center;
        margin-bottom: vw(10, $desktop_max);

        .label {
            margin-right: vw(10, $desktop_max);
        }
    }

    .control-btns{
        margin-bottom: vw(20, $desktop_max);
    }


    table {
        width: 100%;
        
        thead {
            display: flex;
            tr {
                display: flex;
                width: 100%;
                td {
                    border: 1px solid white;
                    background-color: #cecece;
                    flex: 1;
                    text-align: start;
                    font-weight: bold;
                    padding: vw(5, 1920) vw(20, 1920);
                }
            }

            
        }
        tbody{
            tr {
                display: flex;
                align-items: center;
                flex: 1;
                border-bottom: 1px solid #cecece;
                &:nth-of-type(2n+0){
                    background-color: #f7f7f7;
                }
                td {
                    flex: 1;
                    display: flex;
                    padding: vw(5, 1920) vw(20, 1920);

                    .key {
                        width: 95%;
                        margin-right: 5%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .copy-to-clipboard {
                        cursor: pointer;
                        &:hover {
                            color: $color_primary;
                        }
                    }
    
                    textarea {
                        resize: none;
                    }

                    .remove-btn {
                        margin-inline-start: vw(10, 1920);
                        background-color: #ff1c1c;

						&:hover {
							color: white;
							background-color: #ff6352;
						}
                    }
                }
            }
        }
    }
}
@import '/src/styles/imports.scss';
$current: $mobile;
.alert-entry-wrapper {
    
}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .alert-entry-wrapper {
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    .alert-entry-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: vw(700, $current);
        padding: vw(10, $current) vw(20, $current);
        margin: vw(10, $current) 0;
        border-radius: vw(15, $current);

        .alert-icon {
            width: vw(30, $current);
            margin-inline-end: vw(10, $current);
        }

        .text-wrapper {
            width: vw(500, $current);

            & > * {    
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .alert-title-wrapper {
                display: flex;
                align-items: center;
                .alert-headline {
                    font-weight: bold;
                    font-size: vw(18, $current);
                    max-width: 72%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .expiry-date {
                    background-color: yellow;
                    color: black;
                    padding: vw(2, $current) vw(5, $current);
                    border-radius: vw(5, $current);
                    font-weight: normal;
                    margin-inline-start: vw(10, $current);
                    font-size: vw(10, $current);
                }
            }

            .alert-body {
                font-weight: 100;
                font-size: vw(14, $current);
            }
        }

        .control-buttons {
            margin-inline-start: auto;
            display: flex;
            align-items: center;
            justify-content: center;

            .control-icon {
                cursor: pointer;
                width: vw(20, $current);
                transition: 0.3s;

                color: #42424266;
                &:hover {
                    color: inherit;
                }
            }

            & > * { 
                margin-inline-start: vw(5, $current);
            }
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .alert-entry-wrapper {
        width: vw(700, $current);
        padding: vw(10, $current) vw(20, $current);
        margin: vw(10, $current) 0;
        border-radius: vw(15, $current);

        .alert-icon {
            width: vw(30, $current);
            margin-inline-end: vw(10, $current);
        }

        .text-wrapper {
            width: vw(500, $current);
            .alert-title-wrapper {
                .alert-headline {
                    font-size: vw(18, $current);
                }

                .expiry-date {
                    padding: vw(2, $current) vw(5, $current);
                    border-radius: vw(5, $current);
                    margin-inline-start: vw(10, $current);
                    font-size: vw(10, $current);
                }
            }

            .alert-body {
                font-size: vw(14, $current);
            }
        }

        .control-buttons {
            .control-icon {
                width: vw(20, $current);
            }

            & > * { 
                margin-inline-start: vw(5, $current);
            }
        }
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .alert-entry-wrapper {
        width: vw(700, $current);
        padding: vw(10, $current) vw(20, $current);
        margin: vw(10, $current) 0;
        border-radius: vw(15, $current);

        .alert-icon {
            width: vw(30, $current);
            margin-inline-end: vw(10, $current);
        }

        .text-wrapper {
            width: vw(500, $current);
            .alert-title-wrapper {
                .alert-headline {
                    font-size: vw(18, $current);
                }

                .expiry-date {
                    padding: vw(2, $current) vw(5, $current);
                    border-radius: vw(5, $current);
                    margin-inline-start: vw(10, $current);
                    font-size: vw(10, $current);
                }
            }

            .alert-body {
                font-size: vw(14, $current);
            }
        }

        .control-buttons {

            .control-icon {
                width: vw(20, $current);
            }

            & > * { 
                margin-inline-start: vw(5, $current);
            }
        }
    }
}
@import '../../../../../styles/imports.scss';

tr {
  td {
    position: relative;

    .edit-icon {
        position: absolute;
        right: vw(10, 1920);
        bottom: vw(10, 1920);
        cursor: pointer;

        &:hover {
            color: $color_primary;
        }
    }

    .img-input-hidden {
        display: none;
    }

    .remove-btn {
        cursor: pointer;

        &:hover {
            color: red;
        }
    }

    .save-btn {
        cursor: pointer;

        &:hover {
            color: $color_primary;
        }
    }
  }  
}
@media (min-width: $tablet_px) {
    tr {
    }
}
@media (min-width: $desktop_px) {
    tr {
    }
}
@media (min-width: $desktop_large_px) {
    tr {
    }
}
@media (min-width: $desktop_max_px) {
    tr {
    }
}
@import '/src/styles/imports.scss';
$current: $mobile;
.add-alert-popup {
    
}

@media (min-width: $tablet_px) {
    $current: $tablet;
    .add-alert-popup {
    }
}

@media (min-width: $desktop_px) {
    $current: $desktop;
    .add-alert-popup {
        position: relative;
        padding: vw(30, $current) vw(20, $current) vw(10, $current);

        .add-alert-color-strip {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: vw(20, $current);
        }

        .add-alert-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            
            .add-alert-title {
                font-size: vw(20, $current);
                font-weight: bold;
            }

            .add-alert-header-settings {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                font-size: vw(12, $current);

                & > * {
                    margin-bottom: vw(5, $current);
                }

                .add-alert-type {

                    select {
                        &.highlight {
                            margin-top: vw(2.5, $current);
                            color: white;
                            background-color: red;
                            animation: blink 3s;
                            animation-iteration-count: infinite;
                            animation-delay: 0;
                        }
                    }
                }
            }
        }

        .add-alert-form {
            width: vw(500, $current);

            & > * {
                margin: vw(10, $current) 0;
            }

            .add-alert-input {
                padding-bottom: 0;

                .float-input {
                    margin: 0;
                    transition: 0.3s;

                    &:disabled {
                        background-color: #42424222;
                    }
                }
            }

            textarea {
                padding: vw(5, $current) vw(10, $current);
                height: vw(300, $current);
                width: 100%;
                resize: none;
                transition: 0.3s;

                &:disabled {
                    background-color: #42424222;
                }
            }

            .add-alert-expiry {
                display: flex;
                align-items: center;
                justify-content: center;

                input {
                    margin: 0;
                    margin-inline-start: vw(10, $current);
                    &:disabled {
                        background-color: #42424222;
                    } 
                }
            }

            .add-alert-save-button {
                margin-inline-start: auto;
            }
        }
    }
}

@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .add-alert-popup {
        padding: vw(30, $current) vw(20, $current) vw(10, $current);

        .add-alert-color-strip {
            height: vw(20, $current);
        }

        .add-alert-header {
            
            .add-alert-title {
                font-size: vw(20, $current);
            }

            .add-alert-header-settings {
                font-size: vw(12, $current);


                & > * {
                    margin-bottom: vw(5, $current);
                }
            }
        }

        .add-alert-form {
            width: vw(500, $current);

            textarea {
                padding: vw(5, $current) vw(10, $current);
                height: vw(300, $current);
            }

            .add-alert-expiry {
                input {
                    margin-inline-start: vw(10, $current);
                }
            }
        }
    }
}

@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .add-alert-popup {
        padding: vw(30, $current) vw(20, $current) vw(10, $current);

        .add-alert-color-strip {
            height: vw(20, $current);
        }

        .add-alert-header {
            
            .add-alert-title {
                font-size: vw(20, $current);
            }

            .add-alert-header-settings {
                font-size: vw(12, $current);


                & > * {
                    margin-bottom: vw(5, $current);
                }
            }
        }
        
        .add-alert-form {
            width: vw(500, $current);

            textarea {
                padding: vw(5, $current) vw(10, $current);
                height: vw(300, $current);
            }

            .add-alert-expiry {
                input {
                    margin-inline-start: vw(10, $current);
                }
            }
        }
    }
}

@keyframes blink {
    0% {
        background-color: transparent;
        color: black;
    }
    50% {
        background-color: #E70127;
        color: white;
    }
    100% { 
        background-color: transparent;
        color: black;
    }
}
@import '/src/styles/imports.scss';
$current: $mobile;
.cms-alerts-wrapper {
    
}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .cms-alerts-wrapper {
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    .cms-alerts-wrapper {

        .alerts-wrapper {

            .choose-language-area {
                margin-bottom: vw(15, $current);
            }

            .add-an-alert-wrapper {
                width: vw(700, $current);
                background-color: transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                height: vw(57, $current);
                border: vw(3, $current) dashed #42424266;
                border-radius: vw(10, $current);

                .add-icon {
                    width: vw(40, $current);
                    color: #42424266;
                }
            }
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .cms-alerts-wrapper {
        
        .choose-language-area {
            margin-bottom: vw(15, $current);
        }

        .alerts-wrapper {
            .add-an-alert-wrapper {
                width: vw(700, $current);
                height: vw(57, $current);
                border-width: vw(3, $current);
                border-radius: vw(10, $current);

                .add-icon {
                    width: vw(40, $current);
                }
            }
        }
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .cms-alerts-wrapper {
        
        .choose-language-area {
            margin-bottom: vw(15, $current);
        }

        .alerts-wrapper {
            .add-an-alert-wrapper {
                width: vw(700, $current);
                height: vw(57, $current);
                border-width: vw(3, $current);
                border-radius: vw(10, $current);

                .add-icon {
                    width: vw(40, $current);;
                }
            }
        }
    }
}
@import '/src/styles/imports.scss';
$current: $mobile;
.object-editor-popup {
    direction: ltr;
}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .object-editor-popup {
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    .object-editor-popup {
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .object-editor-popup {
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .object-editor-popup {
        width: vw(600, $current);
        height: vw(600, $current);
        padding: vw(10, $current) vw(20, $current);

        .object-key-wrapper:not(.object) {
            display: flex;
        }

        .object-key-wrapper {
            margin: vw(2, $current) 0;
        }

        .object-key {
            margin-inline: vw(5, $current);
        }
    }
}
/* Colors */
$color_primary: #00b4b4;
$secondary_primary: #1ec8c8;
$primary-color-opacity: rgb(0, 181, 181, 0.5);
$inactive-color:  #a9d0d0;
$hover-color: #009999;
$mountain: #a5b4c7;
$text-offwhite: rgba(255, 255, 255, 0.9);
$facebook-blue: #0676E8;
$white: #fff;
$black: #303030;
$gray: #f2eff0;

/* Media Sizes in Pixels */
$mobile_px: 320px;
$tablet_px: 768px;
$desktop_px: 1200px;
$desktop_large_px: 1500px;
$desktop_max_px: 1920px;

/* Media Sizes numeric values */
$mobile: 320;
$tablet: 768;
$desktop: 1200;
$desktop_large: 1500;
$desktop_max: 1920;
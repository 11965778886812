@import '/src/styles/imports.scss';
$current: $mobile;
.home-screen-wrapper {
    font-family: 'Rubik';

    .border { 
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        background-color: $color_primary;

        &.bottom {
            bottom: vw(-5, $current);
        }

        &.top {
            top: vw(-5, $current);
        }
    }

    .left-up {
        -webkit-clip-path: polygon(0 0%, 100% 10%, 100% 100%, 0 90%);
        clip-path: polygon(0 0%, 100% 10%, 100% 100%, 0 90%);
    }

    .right-up {
        -webkit-clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0 100%);
        clip-path: polygon(0 10%, 100% 0%, 100% 90%, 0 100%);
    }

    .left-up-top {
        -webkit-clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 100%);
        clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 100%);
    }

    .home-main-section {
        .main-features-info {
            padding-inline: vw(30, $current);
            text-align: center;

            h3 {
                color: $color_primary;
            }

            p {
                font-size: vw(14, $current);
                line-height: vw(20, $current);
                text-align: justify;
                text-align-last: center;
            }
        }

        .features-swiper {
            margin: vw(20, $current) auto;
            height: 80vw;
            width: 70%;

            .feature-slide-inner {
                padding: vw(10, $current) vw(15, $current);
                background-color: $color_primary;
                border-radius: vw(20, $current);
                // filter: drop-shadow(0 0 vw(10, $current) $color_primary);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;

                .main-feature-icon-wrapper {
                    width: vw(100, $current);
                    height: vw(100, $current);

                    img {
                        fill: $white;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .main-features-text-wrapper {

                    &.rtl {
                        direction: rtl;
                    }

                    h5 {
                        color: $white;
                        font-size: vw(16, $current);
                        margin: 0;
                        margin-top: vw(10, $current);
                    }

                    p {
                        margin: 0;
                        margin-top: vw(3, $current);
                        font-size: vw(12, $current);
                        line-height: vw(16, $current);
                        color: $text-offwhite;
                    }
                }
            }
        }
    }
    
    .home-gallery-section {
        width: 100vw;

        .home-main-carousel {
            width: 100vw;
            height: vw(300, $current);

            .in-swiper-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .swiper-pagination {
                .swiper-pagination-bullet {
                    &.swiper-pagination-bullet-active {
                        background-color: $color_primary;
                        box-shadow: 0 0 vw(5, $current) rgba(0,0,0,0.3);
                    }

                    &.swiper-pagination-bullet-active-next,
                    &.swiper-pagination-bullet-active-next-next,
                    &.swiper-pagination-bullet-active-prev,
                    &.swiper-pagination-bullet-active-prev-prev {
                        background-color: $inactive-color;
                        box-shadow: 0 0 vw(5, $current) rgba(0,0,0,0.3);
                        opacity: 1;
                    }
                }
            }
        }
    }

    .home-contact-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        .contact-form-wrapper {
            padding: vw(10, $current) vw(20, $current);
            width: 100vw;

            h2 {
                text-align: center;
                margin: 0;
                margin-bottom: vw(10, $current);
            }

            .contact-form-input {
                margin-bottom: vw(15, $current);
            }

            .contact-form-textarea {
                margin-bottom: vw(15, $current);
            }

            .contact-submit-button {
                width: 100%;
                border-radius: vw(50, $current);
            }
        }


        .clipped-image-wrapper {
            position: relative;
            width: 100vw;

            .contact-us-image {
                width: 100%;
                height: vw(300, $current);

                .image {
                    width: 100%;
                    height: 100vw;
                    object-fit: cover;
                }
            }
        }
    }

    .home-feature-section {
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;

        &.reversed {
            margin-top: vw(15, $current);

        }

        .clipped-image-wrapper {
            position: relative;
            width: 100vw;

            .feature-img-wrapper {
                width: 100%;
                height: vw(200, $current);

                &.right-up {
                    -webkit-clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
                    clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
                }
                &.left-up {
                    -webkit-clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 90%);
                    clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 90%);
                }

                .image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .feature-text-wrapper {
            padding: 0 vw(30, $current);

            h3 {
                color: $color_primary;
            }

            p {
                font-size: vw(14, $current);
                line-height: vw(18, $current);
                text-align: justify;
                text-align-last: center;
            }
        }

        .third-fix {
            display: none;
        }
    }


}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .home-screen-wrapper {
        font-family: 'Rubik';
        width: 100vw;
        position: relative;

        .border { 
            &.bottom {
                bottom: unset;
                left: vw(-5, $current);
            }

            &.top {
                top: vw(-5, $current);
            }
        }

        .home-main-section {
            .main-features-info {
                padding-inline: vw(30, $current);
                display: flex;
                flex-direction: column;
                align-items: center;

                p {
                    width: 70vw;
                    font-size: vw(14, $current);
                    line-height: vw(20, $current);
                }
            }

            .features-swiper {
                width: 100%;
                height: unset;
                margin: vw(20, $current) 0;

                .feature-slide-inner {
                    background-color: unset;
                    width: unset;
                    height: unset;
                    padding: vw(10, $current) vw(15, $current);
                    border-radius: vw(20, $current);

                    &:first-child {
                        margin-left: vw(20, $current);
                    }

                    .main-feature-icon-wrapper {
                        width: vw(100, $current);
                        height: vw(100, $current);

                        .cls-1 {
                            fill: $color_primary;
                        }
                    }

                    .main-features-text-wrapper {
                        h5 {
                            color: $color_primary;
                            font-size: vw(16, $current);
                            margin-top: vw(10, $current);
                        }

                        p {
                            color: $black;
                            margin-top: vw(3, $current);
                            font-size: vw(12, $current);
                            line-height: vw(16, $current);
                        }
                    }
                }
            }
        }
        
        .home-gallery-section {
            .home-main-carousel {
                height: vw(300, $current);

                .swiper-pagination {
                    .swiper-pagination-bullet {
                        &.swiper-pagination-bullet-active {
                            box-shadow: 0 0 vw(5, $current) rgba(0,0,0,0.3);
                        }

                        &.swiper-pagination-bullet-active-next,
                        &.swiper-pagination-bullet-active-next-next,
                        &.swiper-pagination-bullet-active-prev,
                        &.swiper-pagination-bullet-active-prev-prev {
                            box-shadow: 0 0 vw(5, $current) rgba(0,0,0,0.3);
                        }
                    }
                }
            }
        }

        .home-contact-section {
            padding: 0;
            width: unset;
            flex-direction: row;
            justify-content: flex-end;
            height: vw(600, $current);

            &.rtl {
                .clipped-image-wrapper {
    
                    .border {
                        right: vw(-5, $current);
                        left: unset;
                        &.left-up-top {
                            -webkit-clip-path: polygon(0 0, 100% 0%, 90% 100%,0% 100%);
                            clip-path: polygon(0 0, 100% 0%, 90% 100%,0% 100%);
                        }
                    }
    
                    .contact-us-image {
                        &.left-up-top {
                            -webkit-clip-path: polygon(0 0, 100% 0%, 90% 100%,0% 100%);
                            clip-path: polygon(0 0, 100% 0%, 90% 100%,0% 100%);
                        }
                    }
                }
            }

            .contact-form-wrapper {
                width: vw(650, $current);
                padding: vw(10, $current) vw(30, $current);

                h2 {
                    margin-bottom: vw(10, $current);
                }

                .contact-form-textarea {
                    margin-bottom: vw(15, $current);
                    .textarea-wrapper {
                        height: vw(200, $current);
                        .float-textarea {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .contact-form-input {
                    margin-bottom: vw(15, $current);
                    .input-wrapper {
                        height: vw(50, $current);
                        .float-input {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .contact-submit-button {
                    border-radius: vw(50, $current);
                }
            }


            .clipped-image-wrapper {
                height: 100%;

                .border {
                    top: unset;
                    left: vw(-5, $current);
                    &.left-up-top {
                        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%);
                        clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%);
                    }
                }

                .contact-us-image {
                    height: 100%;
                    object-fit: cover;

                    &.left-up-top {
                        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%);
                        clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%);
                    }
                    
                    .image {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .home-feature-section {
            flex-direction: row;
            margin: 0;
            height: vw(300, $current);

            &.rtl {
                .clipped-image-wrapper {
                    .border {
                        &.bottom {
                            bottom: 0;
                            &.right-up {
                                -webkit-clip-path: polygon(0 0%, 100% 0%, 100% 90%, 0 100%);
                                clip-path: polygon(0 0%, 100% 0%, 100% 90%, 0 100%);
                                
                            }
                            &.left-up {
                                -webkit-clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 100%);
                                clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 100%);
                            }
    
                            &.right-up {
                                right: unset;
                                left: vw(-5, $current);
                            }

                            &.left-up {
                                left: unset;
                                right: vw(-5, $current);
                            }
                        }
                    }
                    .feature-img-wrapper {
                        width: vw(384, $current);
                        height: vw(300, $current);
    
                        &.right-up {
                            -webkit-clip-path: polygon(0 0%, 100% 0%, 100% 90%, 0 100%);
                            clip-path: polygon(0 0%, 100% 0%, 100% 90%, 0 100%);
                            
                        }
                        &.left-up {
                            -webkit-clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 100%);
                            clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 100%);
                        }
                    }
                }
            }

            &.reversed {
                flex-direction: row-reverse;
                margin-top: unset;
                align-items: flex-start;

                .feature-text-wrapper {
                    margin-top: unset;
                }
            }

            .clipped-image-wrapper {
                .border {
                    &.bottom {
                        bottom: 0;

                        &.right-up {
                            -webkit-clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0 90%);
                            clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0 90%);
                        }

                        &.left-up {
                            -webkit-clip-path: polygon(0 0%, 100% 10%, 100% 100%, 0 100%);
                            clip-path: polygon(0 0%, 100% 10%, 100% 100%, 0 100%);
                        }

                        &.right-up {
                            left: unset;
                            right: vw(-5, $current);
                        }
                    }
                }
                .feature-img-wrapper {
                    width: vw(384, $current);
                    height: vw(300, $current);

                    &.right-up {
                        -webkit-clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0 90%);
                        clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0 90%);
                        
                    }
                    &.left-up {
                        -webkit-clip-path: polygon(0 0%, 100% 10%, 100% 100%, 0 100%);
                        clip-path: polygon(0 0%, 100% 10%, 100% 100%, 0 100%);
                    }
                }
            }

            .feature-text-wrapper {
                // margin-top: vw(20, $current);
                height: vw(250, $current);
                padding: 0 vw(30, $current);
                overflow: hidden;
                display: flex;
                flex-direction: column;
                justify-content: center;

                h3 {
                    margin-top: 0;
                }

                p {
                    margin: 0;
                    font-size: vw(14, $current);
                    line-height: vw(18, $current);
                    text-align-last: auto;
                }
            }
        }
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    .home-screen-wrapper {
        font-family: 'Rubik';
        width: 100%;
        position: relative;

        .border { 
            &.bottom {
                bottom: unset;
                left: vw(-5, $current);
            }

            &.top {
                top: vw(-5, $current);
            }
        }

        .home-main-section {
            padding-inline: vw(110, $current);
            width: unset;
            .main-features-info {
                padding-inline: vw(30, $current);
                display: flex;
                flex-direction: column;
                align-items: center;

                p {
                    width: vw(600, $current);
                    font-size: vw(14, $current);
                    line-height: vw(20, $current);
                }
            }

            .features-swiper {
                width: 100%;
                padding-inline: vw(5, $current);
                height: unset;
                margin: vw(20, $current) 0;

                .feature-slide-inner {
                    background-color: unset;
                    width: unset;
                    height: unset;
                    padding: vw(10, $current) vw(15, $current);
                    border-radius: vw(20, $current);

                    &:first-child {
                        margin-left: vw(20, $current);
                    }

                    .main-feature-icon-wrapper {
                        width: vw(100, $current);
                        height: vw(100, $current);

                        .cls-1 {
                            fill: $color_primary;
                        }
                    }

                    .main-features-text-wrapper {
                        h5 {
                            color: $color_primary;
                            font-size: vw(16, $current);
                            margin-top: vw(10, $current);
                        }

                        p {
                            color: $black;
                            margin-top: vw(3, $current);
                            font-size: vw(12, $current);
                            line-height: vw(16, $current);
                        }
                    }
                }
            }
        }
        
        .home-gallery-section {
            // margin-top: vw(10, $current);
            width: unset;
            .home-main-carousel {
                height: vw(400, $current);

                .swiper-pagination {
                    .swiper-pagination-bullet {
                        &.swiper-pagination-bullet-active {
                            box-shadow: 0 0 vw(5, $current) rgba(0,0,0,0.3);
                        }

                        &.swiper-pagination-bullet-active-next,
                        &.swiper-pagination-bullet-active-next-next,
                        &.swiper-pagination-bullet-active-prev,
                        &.swiper-pagination-bullet-active-prev-prev {
                            box-shadow: 0 0 vw(5, $current) rgba(0,0,0,0.3);
                        }
                    }
                }
            }
        }

        .home-contact-section {
            height: vw(400, $current);
            .contact-form-wrapper {
                width: vw(400, $current);
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
                padding: vw(10, $current) vw(50, $current);

                h2 {
                    margin-bottom: vw(10, $current);
                }

                .contact-submit-button {
                    border-radius: vw(50, $current);
                }

                .contact-form-textarea {
                    margin-bottom: vw(10, $current);
                    .textarea-wrapper {
                        height: vw(150, $current);
                        .float-textarea {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .contact-form-input {
                    margin-bottom: vw(10, $current);
                    .input-wrapper {
                        height: vw(40, $current);
                        .float-input {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }


            .clipped-image-wrapper {
                height: 100%;
                width: 50%;

                .contact-us-image {
                    height: 100%;
                    width: 100%;

                    .image {
                        height: 100%;
                        width: 100%;
                    }

                }
            }
        }

        .home-feature-section {
            width: unset;
            flex-direction: row;
            margin: 0;
            height: vw(300, $current);

            &.rtl {
                .clipped-image-wrapper {
                    .border {
                        &.bottom {
                            bottom: 0;
                            &.right-up{
                                -webkit-clip-path: polygon(0 0%, 100% 0%, 100% 90%, 0 100%);
                                clip-path: polygon(0 0%, 100% 0%, 100% 90%, 0 100%);
                            }
                            &.left-up {
                                -webkit-clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 100%);
                                clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 100%);
                            }
    
                            &.right-up {
                                right: unset;
                                left: vw(-5, $current);
                            }

                            &.left-up {
                                left: unset;
                                right: vw(-5, $current);
                            }
                        }
                    }
                    .feature-img-wrapper {
                        &.right-up {
                            -webkit-clip-path: polygon(0 0%, 100% 0%, 100% 90%, 0 100%);
                            clip-path: polygon(0 0%, 100% 0%, 100% 90%, 0 100%);
                        }
                        &.left-up {
                            -webkit-clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 100%);
                            clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 100%);
                        }
                    }
                }
            }

            &.reversed {
                flex-direction: row-reverse;
                margin-top: unset;
                align-items: flex-start;

                .feature-text-wrapper {
                    margin-top: unset;
                    -webkit-clip-path: polygon(0 0%, 100% 10%, 100% 100%, 0 100%);
                    clip-path: polygon(0 0%, 100% 10%, 100% 100%, 0 100%);
                    // margin-inline-end: vw(5, $current);
                }

                .third-fix {
                    -webkit-clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 100%);
                    clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 100%);
                }
            }

            .clipped-image-wrapper {
                width: 100%;
                height: 100%;
                flex: 1;
                .border {
                    &.bottom {
                        display: none;
                        bottom: 0;

                        &.left-up {
                            -webkit-clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 100%);
                            clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 100%);
                        }

                        &.right-up {
                            -webkit-clip-path: polygon(0 0%, 100% 0%, 100% 90%, 0 100%);
                            clip-path: polygon(0 0%, 100% 0%, 100% 90%, 0 100%);
                        }
                        &.right-up {
                            left: unset;
                            right: unset;
                        }
                    }
                }
                .feature-img-wrapper {
                    width: 100%;
                    height: unset;
                    .image {
                        width: 100%;
                        height: 100%;
                    }

                    &.left-up ,
                    &.right-up {
                        width: unset;
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }

                    &.left-up {
                        -webkit-clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 100%);
                        clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 100%);
                    }

                    &.right-up {
                        -webkit-clip-path: polygon(0 0%, 100% 0%, 100% 90%, 0 100%);
                        clip-path: polygon(0 0%, 100% 0%, 100% 90%, 0 100%);
                    }
                }
            }

            .feature-text-wrapper {
                flex: 1;
                background-color: $color_primary;
                // margin-inline-start: vw(10, $current);
                // width: vw(400, $current);
                margin-top: unset;
                width: unset;
                height: 100%;
                // height: vw(300, $current);
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                padding: vw(30, $current);
                overflow: hidden;

                -webkit-clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0 90%);
                        clip-path: polygon(0 0%, 100% 0%, 100% 100%, 0 90%);

                h3 {
                    margin-top: 0;
                    color: $white;
                }

                p {
                    font-size: vw(14, $current);
                    line-height: vw(18, $current);
                    text-align-last: auto;
                    color: $text-offwhite;
                }
            }

            .third-fix {
                display: unset;
                flex: 1;
                background-color: $inactive-color;
                width: 100%;
                height: 100%;

                -webkit-clip-path: polygon(0 0%, 100% 0%, 100% 90%, 0 100%);
                        clip-path: polygon(0 0%, 100% 0%, 100% 90%, 0 100%);
            }
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .home-screen-wrapper {
        .border { 
            &.bottom {
                left: vw(-5, $current);
            }

            &.top {
                top: vw(-5, $current);
            }
        }

        .home-main-section {
            padding-inline: vw(110, $current);
            .main-features-info {
                padding-inline: vw(30, $current);

                p {
                    width: vw(600, $current);
                    font-size: vw(14, $current);
                    line-height: vw(20, $current);
                }
            }

            .features-swiper {
                margin: vw(20, $current) 0;
                padding-inline: vw(100, $current);

                .feature-slide-inner {
                    padding: vw(10, $current) vw(15, $current);
                    border-radius: vw(20, $current);

                    &:first-child {
                        margin-left: vw(20, $current);
                    }

                    .main-feature-icon-wrapper {
                        width: vw(100, $current);
                        height: vw(100, $current);
                    }

                    .main-features-text-wrapper {
                        h5 {
                            font-size: vw(16, $current);
                            margin-top: vw(10, $current);
                        }

                        p {
                            margin-top: vw(3, $current);
                            font-size: vw(12, $current);
                            line-height: vw(16, $current);
                        }
                    }
                }
            }
        }
        
        .home-gallery-section {
            .home-main-carousel {
                height: vw(400, $current);

                .swiper-pagination {
                    .swiper-pagination-bullet {
                        &.swiper-pagination-bullet-active {
                            box-shadow: 0 0 vw(5, $current) rgba(0,0,0,0.3);
                        }

                        &.swiper-pagination-bullet-active-next,
                        &.swiper-pagination-bullet-active-next-next,
                        &.swiper-pagination-bullet-active-prev,
                        &.swiper-pagination-bullet-active-prev-prev {
                            box-shadow: 0 0 vw(5, $current) rgba(0,0,0,0.3);
                        }
                    }
                }
            }
        }

        .home-contact-section {

            .contact-form-wrapper {
                width: vw(500, $current);
                padding: vw(10, $current) vw(50, $current);

                h2 {
                    margin-bottom: vw(10, $current);
                }

                .contact-submit-button {
                    border-radius: vw(50, $current);
                }

                .contact-form-textarea {
                    margin-bottom: vw(10, $current);
                    .textarea-wrapper {
                        height: vw(200, $current);
                    }
                }

                .contact-form-input {
                    margin-bottom: vw(10, $current);
                    .input-wrapper {
                        height: vw(40, $current);
                    }
                }
            }


            // .clipped-image-wrapper {

            //     .contact-us-image {
            //         height: vw(300, $current);
            //     }
            // }
        }

        .home-feature-section {
            height: vw(300, $current);

            &.rtl {
                .clipped-image-wrapper {
                    .border {
                        &.bottom {
                            &.right-up {
                                left: vw(-5, $current);
                            }

                            &.left-up {
                                right: vw(-5, $current);
                            }
                        }
                    }
                    .feature-img-wrapper {
                        clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 100%);
                    }
                }
            }

            .clipped-image-wrapper {
                .border {
                    &.bottom {

                        &.right-up {
                            right: vw(-5, $current);
                        }
                    }
                }
            }

            .feature-text-wrapper {
                padding: vw(30, $current);

                p {
                    font-size: vw(14, $current);
                    line-height: vw(18, $current);
                }
            }
        }
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .home-screen-wrapper {
        .border { 
            &.bottom {
                left: vw(-5, $current);
            }

            &.top {
                top: vw(-5, $current);
            }
        }

        .home-main-section {
            padding-inline: vw(110, $current);
            .main-features-info {
                padding-inline: vw(30, $current);

                p {
                    width: vw(600, $current);
                    font-size: vw(14, $current);
                    line-height: vw(20, $current);
                }
            }

            .features-swiper {
                margin: vw(20, $current) 0;
                padding-inline: vw(300, $current);

                .feature-slide-inner {
                    padding: vw(10, $current) vw(15, $current);
                    border-radius: vw(20, $current);

                    &:first-child {
                        margin-left: vw(20, $current);
                    }

                    .main-feature-icon-wrapper {
                        width: vw(100, $current);
                        height: vw(100, $current);
                    }

                    .main-features-text-wrapper {
                        h5 {
                            font-size: vw(16, $current);
                            margin-top: vw(10, $current);
                        }

                        p {
                            margin-top: vw(3, $current);
                            font-size: vw(12, $current);
                            line-height: vw(16, $current);
                        }
                    }
                }
            }
        }
        
        .home-gallery-section {
            .home-main-carousel {
                height: vw(400, $current);

                .swiper-pagination {
                    .swiper-pagination-bullet {
                        &.swiper-pagination-bullet-active {
                            box-shadow: 0 0 vw(5, $current) rgba(0,0,0,0.3);
                        }

                        &.swiper-pagination-bullet-active-next,
                        &.swiper-pagination-bullet-active-next-next,
                        &.swiper-pagination-bullet-active-prev,
                        &.swiper-pagination-bullet-active-prev-prev {
                            box-shadow: 0 0 vw(5, $current) rgba(0,0,0,0.3);
                        }
                    }
                }
            }
        }

        .home-contact-section {

            .contact-form-wrapper {
                width: vw(500, $current);
                padding: vw(10, $current) vw(50, $current);

                h2 {
                    margin-bottom: vw(10, $current);
                }

                .contact-submit-button {
                    border-radius: vw(50, $current);
                }

                .contact-form-textarea {
                    margin-bottom: vw(10, $current);
                    .textarea-wrapper {
                        height: vw(200, $current);
                    }
                }

                .contact-form-input {
                    margin-bottom: vw(10, $current);
                    .input-wrapper {
                        height: vw(40, $current);
                    }
                }
            }
        }

        .home-feature-section {
            height: vw(400, $current);

            &.rtl {
                .clipped-image-wrapper {
                    .border {
                        &.bottom {
                            &.right-up {
                                left: vw(-5, $current);
                            }

                            &.left-up {
                                right: vw(-5, $current);
                            }
                        }
                    }
                    .feature-img-wrapper {
                        clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0 100%);
                    }
                }
            }

            .clipped-image-wrapper {
                .border {
                    &.bottom {
                        &.right-up {
                            right: vw(-5, $current);
                        }
                    }
                }
            }

            .feature-text-wrapper {
                padding: vw(30, $current);

                p {
                    width: vw(400, $current);
                    font-size: vw(14, $current);
                    line-height: vw(18, $current);
                }
            }
        }
    }
}
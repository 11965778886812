@import '/src/styles/imports.scss';
$current: $mobile;
.button-wrapper {
    padding: vw(10, $current) vw(20, $current);
    border-radius: vw(10, $current);
    border: none;
    background-color: $color_primary;
    color: $white;
    font-size: vw(16, $current);
    font-weight: bold;
    font-family: 'Rubik';
    cursor: pointer;
}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .button-wrapper {
        padding: vw(10, $current) vw(20, $current);
        border-radius: vw(10, $current);
        font-size: vw(16, $current);
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    .button-wrapper {
        padding: vw(10, $current) vw(20, $current);
        border-radius: vw(10, $current);
        font-size: vw(16, $current);
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .button-wrapper {
        padding: vw(10, $current) vw(20, $current);
        border-radius: vw(10, $current);
        font-size: vw(16, $current);
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .button-wrapper {
        padding: vw(7.5, $current) vw(15, $current);
        border-radius: vw(10, $current);
        font-size: vw(12, $current);
    }
}
@import "../../../styles/imports.scss";

.notification-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: vw(10, $desktop_max);
    position: relative;
    color: white;
    left: -100%;
    background-color: rgb(254, 60, 60);
    padding: vw(10, $desktop_max) vw(30, $desktop_max);
    padding-inline: 0;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    transition: 0.8s;
    cursor: default;

    &.slide {
        transition: 0.8s;
        left: vw(40, $desktop_max);
    }

    .icon-wrapper {
        padding: 0 vw(20, $desktop_max);
        font-size: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .title-text-wrapper {
        .notification-title {
            font-weight: bold;
        }

        .notification-text {
            font-size: 14px;
        }
    }
    .close-notification {
        padding-inline: vw(20, $desktop_max);
        cursor: pointer;
    }
}

@import '../../../../styles/imports.scss';

.dropdown-menu-wrapper {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: vw(10, $desktop_max);
    margin-inline: vw(10, $desktop_max);
    z-index: 1000;

    &:hover {
        .items-wrapper {
            overflow-y: scroll;
            max-height: vw(500, $desktop_max);
            padding: vw(10, $desktop_max);
            border: 1px solid $color_primary;

            &::-webkit-scrollbar{
                display: none;
            }
        }
    }

    .items-wrapper {
        max-height: 0;
        overflow: hidden;
        position: absolute;         
        top: 100%;
        box-shadow: 0 0 10px rgba(0,0,0,0.3);
        background-color: white;
        width: 100%;
        transition: 0.5s;
        border: 0px solid $color_primary;
        min-width: vw(150, $desktop_max);
    }

    .dropdown-item {
        padding: vw(10, $desktop_max) 0;
        color: $color_primary;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;

        &:not(:last-of-type){
            border-bottom: 1px solid #cecece;
        }
    }
}
@media (min-width: $tablet_px) {
    .dropdown-menu-wrapper {
    }
}
@media (min-width: $desktop_px) {
    .dropdown-menu-wrapper {
    }
}
@media (min-width: $desktop_large_px) {
    .dropdown-menu-wrapper {
    }
}
@media (min-width: $desktop_max_px) {
    .dropdown-menu-wrapper {
    }
}
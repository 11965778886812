@import '/src/styles/imports.scss';

$current: $mobile;

.splash-screen-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $color_primary;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1s;
    z-index: 100000;

    &.hide {
        opacity: 0;
        pointer-events: none;
    }

    .splash-logo {
        font-size: vw(55, $current);
        font-family: 'Pacifico';
        font-weight: 100;
        color: white;
    }
}

@media (min-width: $tablet_px) {
    $current: $tablet;

    .splash-screen-wrapper {

        .splash-logo {
            font-size: vw(55, $current);
        }
    }
}

@media (min-width: $desktop_px) {
    $current: $desktop;

    .splash-screen-wrapper {

        .splash-logo {
            font-size: vw(55, $current);
        }
    }
}

@media (min-width: $desktop_large_px) {
    $current: $desktop_large;

    .splash-screen-wrapper {

        .splash-logo {
            font-size: vw(55, $current);
        }
    }
}

@media (min-width: $desktop_max_px) {
    $current: $desktop_max;

    .splash-screen-wrapper {

        .splash-logo {
            font-size: vw(55, $current);
        }
    }
}
@mixin cms-padding {
    $current: 320;
    padding: vw(75, $current) vw(30, $current);

    @media (min-width: 768px) {
        $current: 768;
        padding: vw(75, $current) vw(30, $current);
        
    }
    @media (min-width: 1200px) {
        $current: 1200;
        padding: vw(75, $current) vw(100, $current);
        
    }
    @media (min-width: 1500px) {
        $current: 1500;
        padding: vw(75, $current) vw(100, $current);
        
    }
    @media (min-width: 1920px) {
        $current: 1920;
        padding: vw(75, $current) vw(100, $current);
    }
}
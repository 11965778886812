@import '/src/styles/imports.scss';
$current: $mobile;
.auto-complete-wrapper {
    position: relative;
}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .auto-complete-wrapper {
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    .auto-complete-wrapper {
        position: relative;

        .auto-complete-input {
            height: vw(45, $current);
        }

        .auto-complete-options-wrapper {
            z-index: 1000;
            position: absolute;
            padding: 0 vw(5, $current);
            border-radius: vw(10, $current);
            max-height: 0;
            background-color: white;
            width: 100%;
            left: 0;
            top: 100%;
            overflow: hidden;
            transition: 0.3s;

            &.show {
                max-height: vw(500, $current);
                padding: vw(10, $current) vw(5, $current);
                box-shadow: 0 10px 10px 0px rgba(0,0,0,0.2);
                border: 1px solid #eaeaea99;
            }

            .divider {
                width: 100%;
                border-top: 1px solid #eaeaea99;
            }

            .auto-complete-option-wrapper {
                padding: vw(10, $current) vw(5, $current);
                cursor: pointer;

                &:hover {
                    background-color: #efefef;
                }

                .auto-complete-option-bold {
                    font-weight: bold;
                }
            }            
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .auto-complete-wrapper {
        .auto-complete-input {
            height: vw(45, $current);
        }
        .auto-complete-options-wrapper {
            padding: 0 vw(5, $current);
            border-radius: vw(10, $current);
            &.show {
                max-height: vw(500, $current);
                padding: vw(10, $current) vw(5, $current);
            }

            .auto-complete-option-wrapper {
                padding: vw(10, $current) vw(5, $current);
            }            
        }
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .auto-complete-wrapper {
        .auto-complete-input {
            height: vw(45, $current);
        }
        .auto-complete-options-wrapper {
            padding: 0 vw(5, $current);
            border-radius: vw(10, $current);
            &.show {
                max-height: vw(500, $current);
                padding: vw(10, $current) vw(5, $current);
            }

            .auto-complete-option-wrapper {
                padding: vw(10, $current) vw(5, $current);
            }            
        }
    }
}
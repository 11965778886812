@import '../styles/imports.scss';

.popup-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.3s;

    .backdrop {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.3);
        opacity: 0;
        transition: 0.3s;
        cursor: pointer;

        &.fade-in {
            opacity: 1;
        }
    }

    .overlay{
        display: flex;
        justify-content: center;
        background-color: white;
        border-radius: vw(10, $desktop_max);
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        z-index: 3;
        width: fit-content;
        height: fit-content;
        transition: 0.3s;
    }

    
}

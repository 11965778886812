@import '/src/styles/imports.scss';
$current: $mobile;
.treks-counter-wrapper {
    cursor: pointer;
    border: none;
    background-color: transparent;

    &:disabled {
        color: $black;
        cursor: default;
    }
}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .treks-counter-wrapper {
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    .treks-counter-wrapper {

        .treks-counter {
            font-size: vw(14, $current);
            font-weight: bold;
        }
        .treks-counter-label {
            font-size: vw(12, $current);
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .treks-counter-wrapper {

        .treks-counter {
            font-size: vw(14, $current);
            font-weight: bold;
        }
        .treks-counter-label {
            font-size: vw(12, $current);
        }
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .treks-counter-wrapper {

        .treks-counter {
            font-size: vw(14, $current);
            font-weight: bold;
        }
        .treks-counter-label {
            font-size: vw(12, $current);
        }
    }
}
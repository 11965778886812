@import '/src/styles/imports.scss';
$current: $mobile;
.user-treks-popup {
    
}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .user-treks-popup {
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    .user-treks-popup {
        padding: vw(20, $current) vw(40, $current);

        .user-treks-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: vw(16, $current);
            font-weight: bold;

            .pagination-controls {
                display: flex;

                .pagination-control-wrapper {
                    flex: 1;

                    .pagination-control {
                        cursor: pointer;
                        width: vw(18, $current);
                        color: $color_primary;
                    }
                }
            }
        }

        .user-treks-wrapper {
            width: vw(600, $current);
            max-height: vw(600, $current);
            margin: vw(10, $current) 0;
            border: 1px solid $gray;
            transition: 0.3s;

            .trek-entry {
                width: 100%;
                padding: vw(10, $current) vw(10, $current);
                display: flex;

                &:not(.header):nth-of-type(2n + 1) {
                    background-color: $gray;
                }

                &.header {
                    background-color: $color_primary;
                    color: $white;
                    font-weight: bold;
                }

                .trek-entry-column {
                    flex: 1;

                    &.link {
                        cursor: pointer;
                        color: $hover-color;
                    }
                }
            }

            .no-treks-found {
                height: vw(200, $current);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .user-treks-popup {
        padding: vw(20, $current) vw(40, $current);

        .user-treks-header {
            font-size: vw(16, $current);
        }

        .user-treks-wrapper {
            width: vw(800, $current);
            margin: vw(10, $current) 0;
            max-height: vw(600, $current);

            .trek-entry {
                padding: vw(10, $current) vw(10, $current);
            }

            .no-treks-found {
                height: vw(200, $current);
            }
        }
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .user-treks-popup {
        padding: vw(20, $current) vw(40, $current);

        .user-treks-header {
            font-size: vw(16, $current);
        }

        .user-treks-wrapper {
            width: vw(1000, $current);
            margin: vw(10, $current) 0;
            max-height: vw(600, $current);

            .trek-entry {
                padding: vw(10, $current) vw(10, $current);
            }

            .no-treks-found {
                height: vw(300, $current);
            }
        }
    }
}
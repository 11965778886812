@import '/src/styles/imports.scss';
$current: $mobile;
.header-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: vw(40, $current);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    padding-inline: vw(20, $current);
    transition: 0.3s;

    &.scrolled {
        background-color: $white;
        box-shadow: 0 0 vw(10, $current) rgba(0,0,0,0.3);
        
        .scroll-indicator-wrapper {
            display: unset;
        }

        .header-logo {
            color: $color_primary;
        }

        .burger-menu-icon-wrapper {
            .burger-menu-icon{
                color: $color_primary;
            }
        }

        .burger-menu-overlay {
            color: $color_primary;
        }
    }

    .scroll-indicator-wrapper {
        display: none;
        left: 0;
        position: absolute;
        top: 100%;
        height: vw(4, $current);
        width: 100%;

        .scroll-indicator {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            background-color: $color_primary;
            // box-shadow: 0 0 vw(5, $current) rgba(0,0,0,0.5);
            border: 0.5px solid $inactive-color;
        }
    }

    .header-logo {
        font-family: "Pacifico", cursive;
        font-weight: 300;
        color: $white;
        font-size: vw(20, $current);
        transition: 0.3s;
    }

    .burger-menu-icon-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: vw(35, $current);
        height: vw(35, $current);
        cursor: pointer;

        .burger-menu-icon {
            width: vw(24, $current);
            height: vw(24, $current);
            color: $white;
            transition: 0.3s;
        }
    }

    .burger-menu-wrapper {
        width: 100vw;
        height: 100%;
        z-index: 300;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: flex-end;
        pointer-events: none;
        & > * {
            transition: 0.3s;
        }

        &.open {
            pointer-events: all;
            .burger-menu-backdrop {
                opacity: 1;
            }
    
            .burger-menu-overlay {
                width: 70%;
            }
        }

        .burger-menu-backdrop {
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: 0 0 vw(5, $current) vw(2, $current) rgba(255,255,255,0.5);
            background-color: rgba(0,0,0,0.3);
            opacity: 0;
        }

        .burger-menu-overlay {
            overflow: hidden;
            height: 100%;
            width: 0;
            color: $white;
            background-color: $white;
            position: relative;

            .burger-menu-close-wrapper {
                height: vw(50, $current);
                display: flex;
                align-items: center;
                padding-inline: vw(15, $current);

                .burger-menu-close {
                    cursor: pointer;
                    width: vw(24, $current);
                    color: $color_primary;
                }
            }

            .burger-menu-item {
                cursor: pointer;
                display: flex;
                align-items: center;
                padding-inline: vw(15, $current);
                width: 100%;
                height: vw(50, $current);
                font-size: vw(16, $current);
                font-weight: normal;
                font-family: 'Rubik';
                color: $black;
                
                border-bottom: vw(1, $current) solid rgba(192, 192, 192, 0.35);

                &:last-of-type {
                    border-bottom: none;
                }
            }

            .burger-menu-change-language {
                cursor: pointer;
                color: $black;
                position: absolute;
                bottom: 0;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-inline: vw(15, $current);
                height: vw(50, $current);
                font-size: vw(12, $current);
                border-top: vw(1, $current) solid rgba(192, 192, 192, 0.35);

                .burger-menu-language {
                    width: vw(18, $current);
                    margin-inline-end: vw(5, $current);
                    transition: 0.3s;
                }
            }
        }
    }

}

@media (min-width: $tablet_px) {
    $current: $tablet;
    .header-wrapper {
        height: vw(40, $current);
        padding-inline: vw(20, $current);
    
        .header-logo {
            font-size: vw(20, $current);
        }

        .scroll-indicator-wrapper {
            height: vw(4, $current);

            .scroll-indicator {
                // box-shadow: 0 0 vw(5, $current) vw(2, $current) rgba(255,255,255,0.5);
            }
        }

        .burger-menu-icon-wrapper {
            width: vw(35, $current);
            height: vw(35, $current);
    
            .burger-menu-icon {
                width: vw(24, $current);
                height: vw(24, $current);
            }
        }
    
        .burger-menu-wrapper {
            &.open {        
                .burger-menu-overlay {
                    width: 30%;
                }
            }
    
            .burger-menu-backdrop {
                background-color: rgba(0, 0, 0, 0.3);
            }
    
            .burger-menu-overlay {
                .burger-menu-close-wrapper {
                    height: vw(50, $current);
                    padding-inline: vw(15, $current);
    
                    .burger-menu-close {
                        width: vw(24, $current);
                    }
                }
    
                .burger-menu-item {
                    padding-inline: vw(15, $current);
                    height: vw(50, $current);
                    font-size: vw(16, $current);
                    
                    border-bottom: vw(1, $current) solid rgba(192, 192, 192, 0.35);
                }

                .burger-menu-change-language {
                    padding-inline: vw(15, $current);
                    height: vw(50, $current);
                    font-size: vw(12, $current);
                    border-top: vw(1, $current) solid rgba(192, 192, 192, 0.35);
    
                    .burger-menu-language {
                        width: vw(18, $current);
                        margin-inline-end: vw(5, $current);
                    }
                }
            }
        }
    }
}

@media (min-width: $desktop_px) {
    $current: $desktop;
    .header-wrapper {
        height: vw(70, $current);
        padding-inline: vw(200, $current);

        &.scrolled {
            .burger-menu-wrapper {
                .burger-menu-overlay {
                    color: $color_primary;

                    .burger-menu-item {
                        color: unset;
                    }
                }
            }
        }

        .header-logo {
            font-size: vw(30, $current);
        }

        .scroll-indicator-wrapper {
            height: vw(4, $current);
            .scroll-indicator {
                // box-shadow: 0 0 vw(5, $current) vw(2, $current) rgba(255,255,255,0.5);
            }
        }

        .burger-menu-icon-wrapper {
            display: none;
        }

        .burger-menu-wrapper {
            width: unset;
            height: 100%;
            position: unset;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            pointer-events: all;
            & > * {
                transition: 0.3s;
            }

            .burger-menu-backdrop {
                pointer-events: none;
                display: none;
            }

            .burger-menu-overlay {
                background-color: unset;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: visible;
                height: 100%;
                width: unset;

                .burger-menu-close-wrapper {
                    display: none;
                }

                .burger-menu-item {
                    color: $white;
                    position: relative;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    padding-inline: vw(15, $current);
                    width: unset;
                    height: vw(70, $current);
                    font-size: vw(14, $current);
                    
                    transition: 0.3s;

                    border-bottom: none;

                    &::before {
                        content: '';
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        border-bottom: vw(0, $current) solid $color_primary;
                    }

                    &:hover {
                        &::before {
                            transition: 0.3s;
                            border-bottom: vw(4, $current) solid $color_primary;
                        }
                    }
                }

                .burger-menu-change-language {
                    position: unset;
                    bottom: unset;
                    width: unset;
                    color: unset;
                    padding-inline: vw(15, $current);
                    height: vw(50, $current);
                    font-size: vw(12, $current);
                    border-top:unset;
    
                    .burger-menu-language {
                        width: vw(18, $current);
                        margin-inline-end: vw(5, $current);
                    }

                    .change-language-text {
                        display: none;
                    }
                }
            }
        }
    }
}

@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .header-wrapper {
        height: vw(70, $current);
        padding-inline: vw(110, $current);

        .header-logo {
            font-size: vw(30, $current);
        }

        .scroll-indicator-wrapper {
            height: vw(4, $current);
            .scroll-indicator {
                // box-shadow: 0 0 vw(5, $current) vw(2, $current) rgba(255,255,255,0.5);
            }
        }

        .burger-menu-wrapper {
            .burger-menu-overlay {
                .burger-menu-item {
                    padding-inline: vw(15, $current);
                    height: vw(70, $current);
                    font-size: vw(14, $current);

                    &::before {
                        border-bottom: vw(0, $current) solid $color_primary;
                    }

                    &:hover {
                        &::before {
                            border-bottom: vw(4, $current) solid $color_primary;
                        }
                    }
                }

                .burger-menu-change-language {
                    padding-inline: vw(15, $current);
                    height: vw(50, $current);
                    font-size: vw(12, $current);
    
                    .burger-menu-language {
                        width: vw(18, $current);
                        margin-inline-end: vw(5, $current);
                    }
                }
            }
        }
    }
}

@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .header-wrapper {
        height: vw(70, $current);
        padding-inline: vw(110, $current);

        .header-logo {
            font-size: vw(30, $current);
        }

        .scroll-indicator-wrapper {
            height: vw(4, $current);
            .scroll-indicator {
                // box-shadow: 0 0 vw(5, $current) vw(2, $current) rgba(255,255,255,0.5);
            }
        }

        .burger-menu-wrapper {
            .burger-menu-overlay {
                .burger-menu-item {
                    padding-inline: vw(15, $current);
                    height: vw(70, $current);
                    font-size: vw(14, $current);

                    &::before {
                        border-bottom: vw(0, $current) solid $color_primary;
                    }

                    &:hover {
                        &::before {
                            border-bottom: vw(4, $current) solid $color_primary;
                        }
                    }
                }

                .burger-menu-change-language {
                    padding-inline: vw(15, $current);
                    height: vw(50, $current);
                    font-size: vw(12, $current);
    
                    .burger-menu-language {
                        width: vw(18, $current);
                        margin-inline-end: vw(5, $current);
                    }
                }
            }
        }
    }
}
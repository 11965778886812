@import '../../../../styles/imports.scss';

.parameters-wrapper {
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .search-wrapper{
        width: fit-content;
        background-color: #ececec;
        border-radius: 20px;
        padding: vw(20, $desktop_max) vw(20, $desktop_max);
        display: flex;
        justify-content: center;
        margin-bottom: vw(10, $desktop_max);

        .label {
            margin-right: vw(10, $desktop_max);
        }
    }

    .control-btns{
        margin-bottom: vw(20, $desktop_max);
    }


    table {
        width: 100%;
        
        thead {
            display: flex;
            tr {
                width: 100%;
                display: flex;

                th {
                    border: 1px solid white;
                    background-color: #cecece;
                    flex: 1;
                    text-align: start;
                    padding: vw(5, 1920) vw(20, 1920);
                }
            }            
        }
        tbody{
            tr {
                display: flex;
                align-items: center;
                flex: 1;
                border-bottom: 1px solid #cecece;
                &:nth-of-type(2n+0){
                    background-color: #f7f7f7;
                }
                td {
                    flex: 1;
                    padding: vw(5, 1920) vw(20, 1920);
    
                    textarea {
                        resize: none;
                    }

                    .remove-btn {
                        margin-inline-start: vw(10, 1920);
                        background-color: #ff1c1c;
                    }
                }
            }
        }
    }
}
@media (min-width: $tablet_px) {
    .parameters-wrapper {
    }
}
@media (min-width: $desktop_px) {
    .parameters-wrapper {
    }
}
@media (min-width: $desktop_large_px) {
    .parameters-wrapper {
    }
}
@media (min-width: $desktop_max_px) {
    .parameters-wrapper {
    }
}
@import '../../styles/imports.scss';

.languages-manager-wrapper {
    background-color: white;
    width: 50vw;
    padding: vw(20, $desktop_max) vw(50, $desktop_max);
    border-radius: 20px;

    table {
        width: 100%;
        
        thead {
            display: flex;
            tr {
                width: 100%;
                display: flex;
                   
                th {
                    border: 1px solid white;
                    background-color: #cecece;
                    flex: 1;
                    text-align: start;
                    padding: vw(5, 1920) vw(20, 1920);

                    &:first-of-type{
                        flex: unset;
                    }

                }
            }  
        }
        tbody{
            tr {
                display: flex;
                align-items: center;
                flex: 1;
                border-bottom: 1px solid #cecece;
                &:nth-of-type(2n+0){
                    background-color: #f7f7f7;
                }
                td {
                    position: relative;
                    flex: 1;
                    padding: vw(5, 1920) vw(10, 1920);

                    &:first-of-type{
                        flex: unset;
                    }

                    textarea {
                        resize: none;
                    }

                    .remove-btn {
                        margin-inline-start: vw(10, 1920);
                        background-color: #ff1c1c;
                    }
                    
                    .id {
                        text-align: center;
                        width: vw(30, $desktop_max);
                        -moz-appearance: textfield;
                        
                        &::-webkit-inner-spin-button,
                        &::-webkit-outer-spin-button{
                            -webkit-appearance: none;
                            margin: 0;
                        }
                    }

                    .indicator {
                        position: absolute;
                    }
                }
            }
        }
    }
}
@media (min-width: $tablet_px) {
    .languages-manager-wrapper {
    }
}
@media (min-width: $desktop_px) {
    .languages-manager-wrapper {
    }
}
@media (min-width: $desktop_large_px) {
    .languages-manager-wrapper {
    }
}
@media (min-width: $desktop_max_px) {
    .languages-manager-wrapper {
    }
}
@import '/src/styles/imports.scss';
$current: $mobile;
.content-users-wrapper {
    
}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .content-users-wrapper {
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    .content-users-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .header {
            width: vw(400, $current);
            height: vw(50, $current);
        }
        .user-details {
            border-top: 2px solid $gray;
            width: 100%;
            display: flex;
            align-items: flex-start;
            margin-top: vw(30, $current);
            padding: vw(30, $current) vw(60, $current);

            .left-side {
                width: vw(300, $current);
                height: vw(300, $current);
                border-radius: 50%;
                overflow: hidden;
                margin-inline-end: vw(20, $current);

                .user-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .right-side {
                flex: 1;
                .right-side-top {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;

                    .right-side-top-left,
                    .right-side-top-right {
                        width: 50%;
                        padding: 0 vw(10, $current);
                    }

                    .right-side-top-right {
                        .user-switch-wrapper {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: vw(5, $current) vw(10, $current);
                            width: 100%;
                            font-size: vw(12, $current);
                            font-weight: bold;
                            border-radius: vw(10, $current);

                            &:nth-of-type(2n + 1) {
                                background-color: $gray;
                            }
                            
                            &.caution {
                                margin-top: vw(5, $current);
                                background-color: #ff919177;
                                margin-bottom: vw(30, $current);
                            }

                            .user-switch-text {
                                display: flex;
                                align-items: center;

                                .verified-badge{ 
                                    align-self: center;
                                    color: $facebook-blue;
                                    width: vw(16, $current);
                                    margin-inline: vw(2, $current);
                                }
                            }

                            .value {
                                font-weight: normal;
                            }
                        }

                        .user-counters-wrapper {
                            margin-top: vw(20, $current);
                            padding: vw(5, $current) vw(10, $current);
                            display: flex;
                            justify-content: space-between;

                            .treks-counter-wrapper {
                                flex: 1;
                            }
                        }
                    }

                    .right-side-top-left {

                        .user-field {
                            height: vw(45, $current);
                        }

                        .float-textarea-wrapper {
                            height: vw(150, $current);
    
                            .textarea-wrapper {
                                height: 100%;
    
                                .float-textarea {
                                    height: 100%;
                                }
                            }
                        }
                    }
                }

                .right-side-bottom {
                    margin-top: vw(15, $current);
                    padding-inline: vw(10, $current);
                }
            }
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .content-users-wrapper {
        width: 80%;

        .header {
            width: vw(400, $current);
            height: vw(50, $current);
        }
        .user-details {
            margin-top: vw(30, $current);
            padding: vw(30, $current) vw(60, $current);

            .left-side {
                width: vw(300, $current);
                height: vw(300, $current);
                margin-inline-end: vw(20, $current);
            }

            .right-side {
                .right-side-top {

                    .right-side-top-left,
                    .right-side-top-right {
                        padding: 0 vw(10, $current);
                    }

                    .right-side-top-right {
                        .user-switch-wrapper {
                            padding: vw(5, $current) vw(10, $current);
                            font-size: vw(12, $current);
                            border-radius: vw(10, $current);

                            &.caution {
                                margin-top: vw(5, $current);
                                margin-bottom: vw(30, $current);
                            }

                            .user-switch-text {
                                .verified-badge {
                                    width: vw(16, $current);
                                    margin-inline: vw(2, $current);
                                }
                            }
                        }

                        .user-counters-wrapper {
                            margin-top: vw(20, $current);
                            padding: vw(5, $current) vw(10, $current);
                        }
                    }
                    
                    .right-side-top-left {
                        .user-field {
                            height: vw(45, $current);
                        }
                        .float-textarea-wrapper {
                            height: vw(150, $current);
                        }
                    }
                }

                .right-side-bottom {
                    margin-top: vw(15, $current);
                    padding-inline: vw(10, $current);
                }
            }
        }
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .content-users-wrapper {
        width: 70%;

        .header {
            width: vw(400, $current);
            height: vw(50, $current);
        }
        .user-details {
            margin-top: vw(30, $current);
            padding: vw(30, $current) vw(60, $current);

            .left-side {
                width: vw(300, $current);
                height: vw(300, $current);
                margin-inline-end: vw(20, $current);
            }

            .right-side {
                .right-side-top {

                    .right-side-top-left,
                    .right-side-top-right {
                        padding: 0 vw(10, $current);
                    }

                    .right-side-top-right {
                        .user-switch-wrapper {
                            padding: vw(5, $current) vw(10, $current);
                            font-size: vw(12, $current);
                            border-radius: vw(10, $current);

                            &.caution {
                                margin-top: vw(5, $current);
                                margin-bottom: vw(30, $current);
                            }

                            .user-switch-text {
                                .verified-badge {
                                    width: vw(16, $current);
                                    margin-inline: vw(2, $current);
                                }
                            }
                        }

                        .user-counters-wrapper {
                            margin-top: vw(20, $current);
                            padding: vw(5, $current) vw(10, $current);
                        }
                    }

                    .right-side-top-left {
                        .user-field {
                            height: vw(45, $current);
                        }
                        .float-textarea-wrapper {
                            height: vw(150, $current);
                        }
                    }
                }

                .right-side-bottom {
                    margin-top: vw(15, $current);
                    padding-inline: vw(10, $current);
                }
            }
        }
    }
}
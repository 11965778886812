@import '/src/styles/imports.scss';

.cms-header-wrapper {
    background-color: #00b4b4;
    height: 4vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 vw(300, 1920);

    .logo-text {
        font-family: Pacifico;
        color: white;
        font-size: vw(40, 1920);
    }
    
    .nav-menu {
        list-style: none;
        display: inline-flex;
        color: white;
        font-weight: bold;
        height: 100%;
        
        .nav-item {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            color: white;
            font-size: vw(16, 1920);
            padding: 0 vw(15, 1920);
            cursor: pointer;

            &::after{
                transition: 0.2s;
                border: 0px solid;
            }

            &:hover {
                position: relative;
                &::after{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    content: "";
                    width: 100%;
                    border-bottom: 3px solid white;
                }
            }
        }
    }
}

@import '../../../../styles/imports.scss';
$size: $desktop_max;
.categories-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .controls-wrapper {
        margin-bottom: vw(20, $size);
        & > *:not(:nth-of-type(1)){
            margin-left: vw(5, $size);
        }
    }

    table {
        overflow: visible;
        width: 50vw;
        td{
            flex: 1;
            position: relative;
            padding: vw(5, $size) vw(10, $size);

            .indicator {
                position: absolute;
            }

            &.languages-wrapper{
                padding: 0;
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: stretch;
                
                .language-name{
                    padding: vw(5, $size) 0;
                }

                .languages-names{
                    width: 100%;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                    div {
                        flex: 1;
                        border-top: 2px solid white;
                        border-left: 2px solid white;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding: vw(5, $size) vw(10, $size);
                    }
                    textarea {
                        padding: vw(5, $size) vw(10, $size);
                        border: 1px solid #cecece;

                    }
                    
                    .rtl {
                        direction: rtl;
                    }
                }
            }

            &.number{
                text-align: center;
                width: vw(40, $size);

                input {
                    -moz-appearance: textfield;
                    width: vw(40, $size);
                    text-align: center;

                    &::-webkit-inner-spin-button,
                    &::-webkit-outer-spin-button {
                        margin: 0;
                        -webkit-appearance: none;
                    }
                }
            }

            &.icon{
                width: vw(100, $size);
                text-align: center;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                .upload-icon {
                    cursor: pointer;

                    &:hover {
                        color: $color_primary;
                    }
                }

                .delete-icon {
                    position: absolute;
                    right: vw(10, $desktop_max);
                    bottom: vw(10, $desktop_max);
                    cursor: pointer;

                    &:hover {
                        color: red;
                    }
                }
            }
        }
        thead {
            td {
                background-color: #eaeaea;
                font-weight: bold;
            }
        } 
        
        tbody {
            tr {
                &:nth-of-type(2n+0){
                    background-color: #f7f7f7;
                }
                td {
                    height: vw(60, $size);
                    &.languages-wrapper{
                        height: vw(60, $size);
                        
                        .languages-names {
                            height: 100%;
                            div {
                                height: 100%;
                                border-top: none;
                            }
                        }
                    }

                    &.icon {
                        position: relative;
                        .thumbnail {
                            &:hover ~ .magnifying{
                                display: unset;
                            }
                        }

                        .magnifying {
                            position: absolute;
                            z-index: 300;
                            top: 80%;
                            left: 25%;
                            width: vw(250, $size);
                            height: vw(250, $size);
                            background-color: white;
                            border: 1px solid #00b4b4;
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: $tablet_px) {
    .categories-wrapper {
    }
}
@media (min-width: $desktop_px) {
    .categories-wrapper {
    }
}
@media (min-width: $desktop_large_px) {
    .categories-wrapper {
    }
}
@media (min-width: $desktop_max_px) {
    .categories-wrapper {
    }
}
@import '/src/styles/imports.scss';
$current: $mobile;
.splash-section-wrapper {
    padding-top: vw(40, $current);
    background-color: $color_primary;
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;

    .splash-section-mountain {
        height: vw(150, $current);
        width: 100%;
        object-fit: cover;
        display: block;
    }

    .splash-section-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $mountain;
        margin: 0;
        height: 100%;
        width: 100vw;

        .splash-section-left-side {
            margin-top: vw(-40, $current);
            height: 100%;
            padding-inline: vw(30, $current);

            .splash-section-title {
                color: $white;
                font-size: vw(30, $current);
                text-transform: uppercase;
                line-height: vw(35, $current);
                font-family: "Open Sans", sans-serif;
                font-weight: 300;

                
                .splash-section-logo {
                    font-family: "Pacifico", cursive;
                    text-transform: none;
                }
            }

            .splash-section-description {
                display: none;
            }

            

            .badge {
                width: vw(300, $current);
            
                img {
                    width: 100%;
                }
            }
        }

        .splash-section-right-side {
            width: 100%;
            height: 100%;
            padding-inline: vw(30, $current);
            display: flex;
            flex-direction: column;
            align-items: center;

            .splash-section-mock-wrapper {
                display: flex;
                width: 100%;
                height: auto;
                img {
                    width: 100%;
                    object-fit: cover;
                    filter: drop-shadow(0 0 vw(5, $current) rgba(0,0,0,0.3));
                }
            }

            img {
                height: 100%;
            }
            .illustration {
                display: none;
                position: absolute;
                width: 100%;
                bottom: vw(20, $current);

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    filter: drop-shadow(0 0 vw(10, $current) $black);
                }
            }
        }
    }
}


@media (min-width: $tablet_px) {
    $current: $tablet;
    .splash-section-wrapper {
        height: vw(500, $current);
        position: relative;
        padding-top: vw(40, $current);

        .splash-section-mountain {
            height: 100%;
            position: absolute;
            z-index: 0;
        }
        .splash-section-content {
            position: relative;
            z-index: 1;
            flex-direction: row-reverse;
            justify-content: center;
            align-items: center;
            background-color: unset;
            padding: vw(30, $current);

            .splash-section-left-side {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-top: unset;
                height: 100%;
                width: vw(300, $current);
                padding-inline: vw(30, $current);
                padding-left: 0;
    
                .splash-section-title {
                    font-size: vw(30, $current);
                    line-height: vw(35, $current);
                    text-shadow: 0 0 vw(5, $current) rgba(0,0,0,0.3);
                }
    
                .splash-section-description {
                    margin: 0;
                    display: unset;
                    text-align: justify;
                    text-align-last: auto;
                    color: $black;
                }
    
            }
    
            .splash-section-right-side {
                height: 100%;
                width: vw(300, $current);
                padding: 0;
                .splash-section-mock-wrapper {
                    height: 100%;
                    img {
                        height: 100%;
                        object-fit: contain;
                        filter: drop-shadow(0 0 vw(5, $current) rgba(0,0,0,0.3));
                    }
                }

            }
        }
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    .splash-section-wrapper {
        height: vw(500, $current);
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: vw(40, $current);
        .splash-section-content {
            width: fit-content;
            padding: vw(30, $current);

            .splash-section-left-side {
                width: vw(350, $current);
                padding-inline: vw(30, $current);
    
                .splash-section-title {
                    font-size: vw(30, $current);
                    line-height: vw(35, $current);
                    text-shadow: 0 0 vw(5, $current) rgba(0,0,0,0.3);
                }
    
            }
    
            .splash-section-right-side {
                width: vw(300, $current);
                .splash-section-mock-wrapper {
                    img {
                        object-fit: contain;
                        filter: drop-shadow(0 0 vw(5, $current) rgba(0,0,0,0.3));
                    }
                }

            }
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .splash-section-wrapper {
        height: vw(650, $current);
        padding-top: vw(40, $current);
        .splash-section-content {
            width: fit-content;
            padding: vw(30, $current);

            .splash-section-left-side {
                width: vw(400, $current);
                padding-inline: vw(30, $current);
    
                .splash-section-title {
                    font-size: vw(35, $current);
                    line-height: vw(45, $current);
                    text-shadow: 0 0 vw(5, $current) rgba(0,0,0,0.3);
                }

                .splash-section-description {
                    font-size: vw(18, $current);
                    line-height: vw(20, $current);
                }
    
            }
    
            .splash-section-right-side {
                width: vw(300, $current);
                .splash-section-mock-wrapper {
                    img {
                        filter: drop-shadow(0 0 vw(5, $current) rgba(0,0,0,0.3));
                    }
                }

            }
        }
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .splash-section-wrapper {
        height: vw(800, $current);
        padding-top: vw(40, $current);
        .splash-section-content {
            width: fit-content;
            padding: vw(30, $current);

            .splash-section-left-side {
                width: vw(350, $current);
                padding-inline: vw(30, $current);
    
                .splash-section-title {
                    font-size: vw(30, $current);
                    line-height: vw(35, $current);
                    text-shadow: 0 0 vw(5, $current) rgba(0,0,0,0.3);
                }
    
            }
    
            .splash-section-right-side {
                width: vw(400, $current);
                .splash-section-mock-wrapper {
                    img {
                        filter: drop-shadow(0 0 vw(5, $current) rgba(0,0,0,0.3));
                    }
                }

            }
        }
    }
}
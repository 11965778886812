@import '/src/styles/imports.scss';
$current: $mobile;
.footer-wrapper {
        top: 0;
        left: 0;
        z-index: 5;
        width: 100%;
        display: flex;
        position: relative;;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: vw(20, $current);
        /* background-color: var(--active-color); */
        background-color: white;
        box-shadow: 0 0 vw(10, $current) rgba(0, 0, 0, 0.5);
    span {
        font-size: vw(12, $current);
        text-decoration: none;

        a {
            color: $color_primary;
        }
    }
    
    .logo {
        font-family: "Pacifico", cursive;
        font-weight: 300;
        color: $color_primary;
        font-size: vw(30, $current);
        margin: 0;
    }
}

@media (min-width: $tablet_px) {
    $current: $tablet;
    .footer-wrapper {
        padding-bottom: vw(20, $current);
        box-shadow: 0 0 vw(10, $current) rgba(0, 0, 0, 0.5);

        span {
            font-size: vw(12, $current);
        }
        
        .logo {
            font-size: vw(30, $current);
        }
    }
}

@media (min-width: $desktop_px) {
    $current: $desktop;
    .footer-wrapper {
        padding-bottom: vw(20, $current);
        box-shadow: 0 0 vw(10, $current) rgba(0, 0, 0, 0.5);
        
        span {
            font-size: vw(12, $current);
        }
        
        .logo {
            font-size: vw(30, $current);
        }
    }
}

@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .footer-wrapper {
        padding-bottom: vw(20, $current);
        box-shadow: 0 0 vw(10, $current) rgba(0, 0, 0, 0.5);
        
        span {
            font-size: vw(12, $current);
        }
        
        .logo {
            font-size: vw(30, $current);
        }
    }
}

@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .footer-wrapper {
        padding-bottom: vw(20, $current);
        box-shadow: 0 0 vw(10, $current) rgba(0, 0, 0, 0.5);
        
        span {
            font-size: vw(12, $current);
        }
        
        .logo {
            font-size: vw(30, $current);
        }
    }
}
@import "../../styles/imports.scss";

.notifications-wrapper {
    position: fixed;
    z-index: 20000;
    display: flex;
    flex-direction: column;
    left: 0;
    bottom: vw(40, $desktop_max);
    transition: 0.5s;
    max-height: 90vh;
}

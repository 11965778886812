@import '/src/styles/imports.scss';
$current: $mobile;
.change-language-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: $white;
    width: 100%;
    padding: 0 vw(30, $current);
    border-radius: vw(30, $current) vw(30, $current) 0 0;
    height: 0;
    overflow: hidden;
    box-shadow: 0 0 vw(15, $current) rgba(0,0,0,0.3);
    transition: 0.3s;
    transform-origin: bottom;
    color: $black;

    &.slide-up {
        margin-top: 0%;
        padding: vw(20, $current) vw(30, $current);
        height: vw(200, $current);
    }

    &.close {
        margin-top: 100%;
        padding: vw(0, $current) vw(30, $current);
        height: vw(0, $current);
    }

    h2 {
        font-size: vw(16, $current);
        text-align: center;
        font-weight: 600;
    }

    .languages-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-basis: auto;

        .language-option {
            width: vw(100, $current);
            height: vw(40, $current);
            margin-bottom: vw(10, $current);
            background-color: transparent;
            border-radius: vw(30, $current);
            border: none;
            cursor: pointer;

            font-size: vw(14, $current);
            color: $black;

            &.active {
                background-color: $color_primary;
                color: $white;
            }
        }
    }
}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .change-language-popup {
        left: unset;
        right: unset;
        width: vw(500, $current);
        padding: 0 vw(30, $current);
        border-radius: vw(30, $current) vw(30, $current) 0 0;
        box-shadow: 0 0 vw(15, $current) rgba(0,0,0,0.3);
    
        &.slide-up {
            padding: vw(20, $current) vw(30, $current);
            height: vw(200, $current);
        }
    
        &.close {
            padding: vw(0, $current) vw(30, $current);
            height: vw(0, $current);
        }
    
        h2 {
            font-size: vw(16, $current);
        }
    
        .languages-wrapper {
    
            .language-option {
                width: vw(100, $current);
                height: vw(40, $current);
                margin-bottom: vw(10, $current);
                border-radius: vw(30, $current);
    
                font-size: vw(14, $current);
            }
        }
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    .change-language-popup {
        width: vw(400, $current);
        padding: 0 vw(30, $current);
        border-radius: vw(30, $current) vw(30, $current) 0 0;
        box-shadow: 0 0 vw(15, $current) rgba(0,0,0,0.3);
    
        &.slide-up {
            padding: vw(20, $current) vw(30, $current);
            height: vw(200, $current);
        }
    
        &.close {
            padding: vw(0, $current) vw(30, $current);
            height: vw(0, $current);
        }
    
        h2 {
            font-size: vw(16, $current);
        }
    
        .languages-wrapper {
    
            .language-option {
                width: vw(100, $current);
                height: vw(40, $current);
                margin-bottom: vw(10, $current);
                border-radius: vw(30, $current);
    
                font-size: vw(14, $current);
            }
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .change-language-popup {
        width: vw(400, $current);
        padding: 0 vw(30, $current);
        border-radius: vw(30, $current) vw(30, $current) 0 0;
        box-shadow: 0 0 vw(15, $current) rgba(0,0,0,0.3);
    
        &.slide-up {
            padding: vw(20, $current) vw(30, $current);
            height: vw(200, $current);
        }
    
        &.close {
            padding: vw(0, $current) vw(30, $current);
            height: vw(0, $current);
        }
    
        h2 {
            font-size: vw(16, $current);
        }
    
        .languages-wrapper {
    
            .language-option {
                width: vw(100, $current);
                height: vw(40, $current);
                margin-bottom: vw(10, $current);
                border-radius: vw(30, $current);
    
                font-size: vw(14, $current);
            }
        }
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .change-language-popup {
        width: vw(400, $current);
        padding: 0 vw(30, $current);
        border-radius: vw(30, $current) vw(30, $current) 0 0;
        box-shadow: 0 0 vw(15, $current) rgba(0,0,0,0.3);
    
        &.slide-up {
            padding: vw(20, $current) vw(30, $current);
            height: vw(200, $current);
        }
    
        &.close {
            padding: vw(0, $current) vw(30, $current);
            height: vw(0, $current);
        }
    
        h2 {
            font-size: vw(16, $current);
        }
    
        .languages-wrapper {
    
            .language-option {
                width: vw(100, $current);
                height: vw(40, $current);
                margin-bottom: vw(10, $current);
                border-radius: vw(30, $current);
    
                font-size: vw(14, $current);
            }
        }
    }
}
@import '/src/styles/imports.scss';
$current: $mobile;

.cms-treks-wrapper {
    
}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .cms-treks-wrapper {
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    .cms-treks-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .search-box {
            width: vw(400, $current);
        }

        .trek-details {
            border-top: 2px solid $gray;
            width: 100%;
            display: flex;
            align-items: flex-start;
            margin-top: vw(30, $current);
            padding: vw(30, $current) vw(60, $current);

            .left-side {
                width: vw(350, $current);
                height: vw(250, $current);
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                
                .swiper-prev-ele,
                .swiper-next-ele {
                    width: vw(30, $current);
                    height: vw(30, $current); 
                    position: absolute;
                    cursor: pointer;
                    color: rgba(255,255,255,0.8);
                    text-shadow: 0 0 10px rgba(0,0,0,0.3);
                    z-index: 1000;
                }

                .swiper-next-ele {
                    right: vw(20, $current);
                }

                .swiper-prev-ele {
                    left: vw(20, $current);
                }

                .swiper-carousel {
                    width: 100%;
                    height: 100%;
                    border-radius: vw(20, $current);
                    .swiper-slide {
                        width: 100%;
                        height: 100%;

                        .trek-image {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    
                    .swiper-pagination {
                        .swiper-pagination-bullet {
                            background-color: $color_primary;
                        }
                    }
                }
            }

            .middle-section {
                flex: 1;
                padding: 0 vw(20, $current);

                .trek-field {
                    height: vw(45, $current);
                }

                .float-textarea-wrapper {
                    height: vw(300, $current);

                    .textarea-wrapper {
                        height: 100%;

                        .float-textarea {
                            height: 100%;
                        }
                    }
                }
            }

            .right-side {
                flex: 1;
                .trek-switch-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: vw(5, $current) vw(10, $current);
                    width: 100%;
                    font-size: vw(12, $current);
                    font-weight: bold;
                    border-radius: vw(10, $current);

                    &:nth-of-type(2n + 1) {
                        background-color: $gray;
                    }
                    
                    &.caution {
                        margin-top: vw(5, $current);
                        background-color: #ff919177;
                        margin-bottom: vw(30, $current);
                    }

                    .trek-switch-text {
                        display: flex;
                        align-items: center;

                        .verified-badge{ 
                            align-self: center;
                            color: $facebook-blue;
                            width: vw(16, $current);
                            margin-inline: vw(2, $current);
                        }
                    }

                    .value {
                        font-weight: normal;
                    }
                }

                .trek-counters-wrapper {
                    margin-top: vw(20, $current);
                    padding: vw(5, $current) vw(10, $current);
                    display: flex;
                    justify-content: space-between;

                    .treks-counter-wrapper {
                        flex: 1;
                    }
                }

                .posted-by {
                    padding: vw(10, $current) vw(10, $current);

                    .author {
                        cursor: pointer;
                        font-weight: bold;
                    }
                }

                .comments-wrapper {
                    height: vw(200, $current);
                    // overflow-y: scroll;
                    & > * {
                        margin-bottom: vw(10, $current);
                    }
                }
            }
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .cms-treks-wrapper {

        .search-box {
            width: vw(400, $current);
        }

        .trek-details {
            margin-top: vw(30, $current);
            padding: vw(30, $current) vw(60, $current);

            .left-side {
                width: vw(600, $current);
                height: vw(400, $current);
                .swiper-prev-ele,
                .swiper-next-ele {
                    width: vw(30, $current);
                    height: vw(30, $current);
                }

                .swiper-next-ele {
                    right: vw(20, $current);
                }

                .swiper-prev-ele {
                    left: vw(20, $current);
                }

                .swiper-carousel {
                    border-radius: vw(20, $current);
                }
            }

            .middle-section {
                padding: 0 vw(20, $current);

                .trek-field {
                    height: vw(45, $current);
                }

                .float-textarea-wrapper {
                    height: vw(300, $current);
                }
            }

            .right-side {
                .trek-switch-wrapper {
                    padding: vw(5, $current) vw(10, $current);
                    font-size: vw(12, $current);
                    border-radius: vw(10, $current);
                    
                    &.caution {
                        margin-top: vw(5, $current);
                        margin-bottom: vw(30, $current);
                    }

                    .trek-switch-text {

                        .verified-badge{ 
                            width: vw(16, $current);
                            margin-inline: vw(2, $current);
                        }
                    }
                }

                .trek-counters-wrapper {
                    margin-top: vw(20, $current);
                    padding: vw(5, $current) vw(10, $current);
                }

                .posted-by {
                    padding: vw(10, $current) vw(10, $current);
                }

                .comments-wrapper {
                    height: vw(200, $current);
                    & > * {
                        margin-bottom: vw(10, $current);
                    }
                }
            }
        }
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .cms-treks-wrapper {

        .search-box {
            width: vw(400, $current);
        }

        .trek-details {
            margin-top: vw(30, $current);
            padding: vw(30, $current) vw(60, $current);

            .left-side {
                width: vw(600, $current);
                height: vw(400, $current);
                
                .swiper-prev-ele,
                .swiper-next-ele {
                    width: vw(30, $current);
                    height: vw(30, $current);
                }

                .swiper-next-ele {
                    right: vw(20, $current);
                }

                .swiper-prev-ele {
                    left: vw(20, $current);
                }

                .swiper-carousel {
                    border-radius: vw(20, $current);
                }
            }

            .middle-section {
                padding: 0 vw(20, $current);

                .trek-field {
                    height: vw(45, $current);
                }

                .float-textarea-wrapper {
                    height: vw(300, $current);
                }
            }

            .right-side {
                .trek-switch-wrapper {
                    padding: vw(5, $current) vw(10, $current);
                    font-size: vw(12, $current);
                    border-radius: vw(10, $current);
                    
                    &.caution {
                        margin-top: vw(5, $current);
                        margin-bottom: vw(30, $current);
                    }

                    .trek-switch-text {

                        .verified-badge{ 
                            width: vw(16, $current);
                            margin-inline: vw(2, $current);
                        }
                    }
                }
                .trek-counters-wrapper {
                    margin-top: vw(20, $current);
                    padding: vw(5, $current) vw(10, $current);
                }

                .posted-by {
                    padding: vw(10, $current) vw(10, $current);
                }

                .comments-wrapper {
                    height: vw(200, $current);

                    & > * {
                        margin-bottom: vw(10, $current);
                    }
                }
            }
        }
    }
}
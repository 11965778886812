@import '/src/styles/imports.scss';
$current: $mobile;
.switch-wrapper {
    position: relative;
    display: inline-block;
    width: vw(30, $current);
    height: vw(17, $current);
    margin: vw(1.5, $current);

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:disabled + .switch-slider {
            cursor: not-allowed;
        }

        &:checked + .switch-slider {
            background-color: $color_primary;

            &:before {
                transform: translateX(vw(6.5, $current));
                background-color: $white;
            }
        }
    }

    .switch-slider {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: vw(30, $current);
        height: vw(17, $current);
        padding-inline: vw(2, $current);
        top: 0;
        left: 0;
        background-color: $white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        transition: 0.3s;
        
        cursor: pointer;

        .switch-thumb {
            position: absolute;
            width: vw(13, $current);
            height: vw(13, $current);
            background-color: $inactive-color;
            transform: translateX(vw(-6.5, $current));
            transition: 0.3s;
        }

        
        .switch-label {
            padding: vw(5, $current) vw(5, $current);
            position: absolute;
            top: 130%;
            width: vw(90, $current);
            text-align: center;
            font-size: vw(12, $current);
            background-color: $white;
            box-shadow: 0 0 vw(5, $current) rgba(0,0,0,0.3);
            color: $black;
            pointer-events: none;
            border-radius: vw(10, $current);
            z-index: 300;
            opacity: 0;
            transition: 0.3s;
        }

        &.rounded {
            border-radius: vw(12, $current);

            .switch-thumb {
                border-radius: 50%;
            }
        }

        &:hover {

            .switch-label {
                pointer-events: all;
                opacity: 1;
            }
        }
    }
}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .switch-wrapper {
        width: vw(30, $current);
        height: vw(17, $current);
        margin: vw(1.5, $current);

        input {
            &:checked + .switch-slider {
                &:before {
                    transform: translateX(vw(6.5, $current));
                }
            }
        }

        .switch-slider {
            width: vw(30, $current);
            height: vw(17, $current);
            padding-inline: vw(2, $current);
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

            .switch-thumb {
                width: vw(13, $current);
                height: vw(13, $current);
                transform: translateX(vw(-6.5, $current));
            }

            
            .switch-label {
                padding: vw(5, $current) vw(5, $current);
                width: vw(90, $current);
                font-size: vw(12, $current);
                box-shadow: 0 0 vw(5, $current) rgba(0,0,0,0.3);
                border-radius: vw(10, $current);
            }

            &.rounded {
                border-radius: vw(12, $current);
            }
        }
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    .switch-wrapper {
        width: vw(30, $current);
        height: vw(17, $current);
        margin: vw(1.5, $current);

        input {
            &:checked + .switch-slider {
                &:before {
                    transform: translateX(vw(6.5, $current));
                }
            }
        }

        .switch-slider {
            width: vw(30, $current);
            height: vw(17, $current);
            padding-inline: vw(2, $current);
            box-shadow: 0 0 vw(5, $current) rgba(0, 0, 0, 0.3);

            .switch-thumb {
                width: vw(13, $current);
                height: vw(13, $current);
                transform: translateX(vw(-6.5, $current));
            }

            
            .switch-label {
                padding: vw(5, $current) vw(5, $current);
                width: vw(90, $current);
                font-size: vw(12, $current);
                box-shadow: 0 0 vw(5, $current) rgba(0,0,0,0.3);
                border-radius: vw(10, $current);
            }

            &.rounded {
                border-radius: vw(12, $current);
            }
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .switch-wrapper {
        width: vw(30, $current);
        height: vw(17, $current);
        margin: vw(1.5, $current);

        input {
            &:checked + .switch-slider {

                .switch-thumb {
                    transform: translateX(vw(6.5, $current));
                }
            }
        }

        .switch-slider {
            width: vw(30, $current);
            height: vw(17, $current);
            padding-inline: vw(2, $current);

            .switch-thumb {
                width: vw(13, $current);
                height: vw(13, $current);
                transform: translateX(vw(-6.5, $current));
            }

            &.rounded {
                border-radius: vw(12, $current);
            }
        }
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .switch-wrapper {
        width: vw(30, $current);
        height: vw(17, $current);
        margin: vw(1.5, $current);

        input {
            &:checked + .switch-slider {
                .switch-thumb {
                    transform: translateX(vw(6.5, $current));
                }
            }
        }

        .switch-slider {
            width: vw(30, $current);
            height: vw(17, $current);
            padding-inline: vw(2, $current);

            .switch-thumb {
                transform: translateX(vw(-6.5, $current));
                width: vw(13, $current);
                height: vw(13, $current);
            }

            &.rounded {
                border-radius: vw(12, $current);
            }
        }
    }
}
@import '../../styles/imports.scss';

.protected-parameters-wrapper {
    padding: vw(20, $desktop_max) vw(40, $desktop_max);

    h2 {
        color: $color_primary;
    }

    table {
        overflow: visible;
        thead {
            tr{
                background-color: #cecece;
                th{
                    padding: vw(5, $desktop_max) 0;

                }
            }
        }
        tbody{
            tr{
                position: relative;
                td{
                    width: 100%;
                    position: relative;

                    input {
                        width: 100%;
                    }

                    .remove-btn {
                        position: absolute;
                        left: 100%;
                        top: 50%;
                        transform: translateY(-50%);
                        cursor: pointer;

                        &:hover {
                            color: red;
                            
                            &.add {
                                color: green;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: $tablet_px) {
    .protected-parameters-wrapper {
    }
}
@media (min-width: $desktop_px) {
    .protected-parameters-wrapper {
    }
}
@media (min-width: $desktop_large_px) {
    .protected-parameters-wrapper {
    }
}
@media (min-width: $desktop_max_px) {
    .protected-parameters-wrapper {
    }
}
@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* @font-face {
    font-family: Pacifico;
    src: url('/public/fonts/Pacifico-Regular.ttf');
} */

/* * {
    font-family: 'Ubuntu';
} */

html {
    height: 100%;
}

:root {
    --background-color: #fff;
    --text-color: #000;
    --active-color: #00a8a8;
    --inactive-color: #a9d0d0;
    --hover-color: #009999;
}

a {
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

body {
    font-family: sans-serif;
    margin: 0;
    overflow-x: hidden;
    height: 100%;
    width: 100%;
}

body.rtl > * {
    direction: rtl;
    font-family: Arial, Helvetica, sans-serif;
}

body .language-change {
    position: fixed;
    top: 0;
    left: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: #00b4b4;
    /* backdrop-filter: blur(30px);
    pointer-events: none;
    opacity: 0; */
}

body .language-change.start {
    animation: 1s switch-language;
}

@keyframes switch-language {
    0% {
        left: -100%;
        /* pointer-events: none;
        opacity: 0; */
    }
    25% {
        left: 0;
        /* pointer-events: all;
        opacity: 1; */
    }
    50% {
        left: 0;
        /* pointer-events: all;
        opacity: 1; */
    }
    100% {
        left: 100%;
        /* pointer-events: none;
        opacity: 0; */
    }
}

.centered {
    margin: 3rem auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.focused {
    font-size: 3rem;
    font-weight: bold;
    color: white;
}

.btn {
    text-decoration: none;
    background-color: teal;
    color: white;
    border-radius: 4px;
    padding: 0.75rem 1.5rem;
    border: 1px solid teal;
    cursor: pointer;
}

.btn:hover,
.btn:active {
    background-color: #11acac;
    border-color: #11acac;
}

.btn--flat {
    cursor: pointer;
    font: inherit;
    color: teal;
    border: none;
    background-color: none;
    text-decoration: none;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
}

.btn--flat:hover,
.btn--flat:active {
    background-color: teal;
    color: white;
}

.react-icons {
    vertical-align: middle;
}

@import '/src/styles/imports.scss';
$current: $mobile;
.rtl {
    .float-textarea-wrapper {
        .float-label {
            left: unset;
            right: vw(20, $desktop_max)
        }

        .error-wrapper {
            right: unset;
            left: 0;
        }
    }
}

.float-textarea-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: vw(5, $desktop_max) 0;
    // border: 1px solid black;

    .float-label-background {
        background-color: white;
        position: absolute;
        left: vw(5, $desktop_max);
        top: 0;
        transform: translateY(0%);
        max-width: 100%;
        transform: scaleX(0);
        transform-origin: center;
        transition: 0.2s ease-in-out;
        &.active {
            transform: scaleX(1);
        }
    }

    .float-label {
        position: absolute;
        background-color: $white;
        padding-inline-end: vw(10, $current);
        padding-inline-start: vw(5, $current);
        left: vw(20, $desktop_max);
        top: 0;
        transform: translateY(75%);
        transition: 0.2s 0.1s;

        &.active {
            // top: vw(-5, $desktop_max);
            transform: translateY(-25%);
            font-size: vw(12, $current);
            color: $color_primary;

            .required-indicator {
                font-size: vw(10, $current);
            }
        }

        .required-indicator {
            color: red;
            font-weight: bold;
            vertical-align: middle;
            font-size: vw(12, $current);
            margin-left: vw(2.5, $desktop_max);
        }
    }
    .textarea-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        .float-textarea {
            width: 100%;
            background-color: white;
            height: vw(250, $current);
            resize: none;
            padding: vw(10, $current) vw(10, $current);
            font-size: vw(12, $current);
            border: vw(1, $current) solid rgba(0, 0, 0, 0.2);
            box-sizing: border-box;
            font-family: Arial;
            border-radius: vw(10, $current);

            &.error {
                border: vw(1, $current) solid red;
            }

            &:focus {
                outline: none;
                border: vw(1, $current) solid $color_primary;
            }
        }

        

        .float-error-icon {
            position: absolute;
            height: vw(15, $desktop_max);
            right: vw(7.5, $desktop_max);
            opacity: 0;
            transition: 0.2s;

            &.show {
                opacity: 1;
            }
        }
    }
    .error-wrapper {
        position: absolute;
        bottom: vw(-10, $current);
        font-size: vw(10, $current);
        right: 0;
        height: vw(10, $current);
        color: red;
    }
}

@media (min-width: $tablet_px) {
    $current: $tablet;
    .float-textarea-wrapper {
    
        .float-label-background {
            left: vw(5, $desktop_max);
        }
    
        .float-label {
            padding-inline-end: vw(10, $current);
            padding-inline-start: vw(5, $current);
            left: vw(5, $desktop_max);
    
            &.active {
                transform: translateY(0%);
                font-size: vw(12, $current);
    
                .required-indicator {
                    font-size: vw(10, $current);
                }
            }
    
            .required-indicator {
                font-size: vw(12, $current);
                margin-left: vw(2.5, $desktop_max);
            }
        }
        .textarea-wrapper {
            .float-textarea {
                padding: 0 vw(10, $current);
                padding-top: vw(10, $current);
                height: vw(300, $current);
                font-size: vw(14, $current);
                border: vw(1, $current) solid rgba(0, 0, 0, 0.2);
                border-radius: vw(10, $current);
    
                &.error {
                    border: vw(1, $current) solid red;
                }
    
                &:focus {
                    border: vw(1, $current) solid $color_primary;
                }
            }
    
            
    
            .float-error-icon {
                height: vw(15, $desktop_max);
                right: vw(7.5, $desktop_max);
            }
        }
        .error-wrapper {
            height: vw(10, $current);
            bottom: vw(-10, $current);
            font-size: vw(10, $current);
        }
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    .float-textarea-wrapper {
    
        .float-label-background {
            left: vw(5, $desktop_max);
        }
    
        .float-label {
            padding-inline-end: vw(10, $current);
            padding-inline-start: vw(5, $current);
            left: vw(5, $desktop_max);
    
            &.active {
                transform: translateY(0%);
                font-size: vw(12, $current);
    
                .required-indicator {
                    font-size: vw(10, $current);
                }
            }
    
            .required-indicator {
                font-size: vw(12, $current);
                margin-left: vw(2.5, $desktop_max);
            }
        }
        .textarea-wrapper {
            .float-textarea {
                padding: 0 vw(10, $current);
                padding-top: vw(10, $current);
                font-size: vw(14, $current);
                height: vw(300, $current);
                border: vw(1, $current) solid rgba(0, 0, 0, 0.2);
                border-radius: vw(10, $current);
    
                &.error {
                    border: vw(1, $current) solid red;
                }
    
                &:focus {
                    border: vw(1, $current) solid $color_primary;
                }
            }
    
            
    
            .float-error-icon {
                height: vw(15, $desktop_max);
                right: vw(7.5, $desktop_max);
            }
        }
        .error-wrapper {
            height: vw(10, $current);
            bottom: vw(-5, $current);
            font-size: vw(10, $current);
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .float-textarea-wrapper {
    
        .float-label-background {
            left: vw(5, $desktop_max);
        }
    
        .float-label {
            padding-inline-end: vw(10, $current);
            padding-inline-start: vw(5, $current);
            left: vw(5, $desktop_max);
    
            &.active {
                transform: translateY(0%);
                font-size: vw(12, $current);
    
                .required-indicator {
                    font-size: vw(10, $current);
                }
            }
    
            .required-indicator {
                font-size: vw(12, $current);
                margin-left: vw(2.5, $desktop_max);
            }
        }
        .textarea-wrapper {
            .float-textarea {
                padding: 0 vw(10, $current);
                padding-top: vw(10, $current);
                font-size: vw(14, $current);
                height: vw(300, $current);
                border: vw(1, $current) solid rgba(0, 0, 0, 0.2);
                border-radius: vw(10, $current);
    
                &.error {
                    border: vw(1, $current) solid red;
                }
    
                &:focus {
                    border: vw(1, $current) solid $color_primary;
                }
            }
    
            
    
            .float-error-icon {
                height: vw(15, $desktop_max);
                right: vw(7.5, $desktop_max);
            }
        }
        .error-wrapper {
            height: vw(10, $current);
            font-size: vw(10, $current);
        }
    }
    
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .float-textarea-wrapper {
    
        .float-label-background {
            left: vw(5, $desktop_max);
        }
    
        .float-label {
            padding-inline-end: vw(10, $current);
            padding-inline-start: vw(5, $current);
            left: vw(5, $desktop_max);
    
            &.active {
                transform: translateY(0%);
                font-size: vw(12, $current);
    
                .required-indicator {
                    font-size: vw(10, $current);
                }
            }
    
            .required-indicator {
                font-size: vw(12, $current);
                margin-left: vw(2.5, $desktop_max);
            }
        }
        .textarea-wrapper {
            .float-textarea {
                padding: 0 vw(10, $current);
                padding-top: vw(10, $current);
                font-size: vw(14, $current);
                height: vw(300, $current);
                border: vw(1, $current) solid rgba(0, 0, 0, 0.2);
                border-radius: vw(10, $current);
    
                &.error {
                    border: vw(1, $current) solid red;
                }
    
                &:focus {
                    border: vw(1, $current) solid $color_primary;
                }
            }
    
            
    
            .float-error-icon {
                height: vw(15, $desktop_max);
                right: vw(7.5, $desktop_max);
            }
        }
        .error-wrapper {
            height: vw(10, $current);
            bottom: vw(-5, $current);
            font-size: vw(10, $current);
        }
    }   
}
@import '/src/styles/imports.scss';
$current: $mobile;
.cms-reports-manager {
    
}
@media (min-width: $tablet_px) {
    $current: $tablet;
    .cms-reports-manager {
    }
}
@media (min-width: $desktop_px) {
    $current: $desktop;
    .cms-reports-manager {
        width: 100%;
        .reports-table {
            width: 100%;
            margin: 0;
            thead {
                width: 100%;
                background-color: $mountain;
                td{
                    flex: 1;
                    padding: vw(5, $current) vw(10, $current);
                }
            }
            tbody {
                tr{
                    td{
                        flex: 1;
                        padding: vw(5, $current) vw(10, $current);
                    }

                    &:nth-of-type(2n + 2){
                        background-color: #eaeaea;
                    }
                }
            }
        }
    }
}
@media (min-width: $desktop_large_px) {
    $current: $desktop_large;
    .cms-reports-manager {
        .reports-table {
            thead {
                background-color: $mountain;
                td{
                    padding: vw(5, $current) vw(10, $current);
                }
            }
            tbody {
                tr{
                    td{
                        padding: vw(5, $current) vw(10, $current);
                    }
                }
            }
        }
    }
}
@media (min-width: $desktop_max_px) {
    $current: $desktop_max;
    .cms-reports-manager {
        .reports-table {
            thead {
                background-color: $mountain;
                td{
                    padding: vw(5, $current) vw(10, $current);
                }
            }
            tbody {
                tr{
                    td{
                        padding: vw(5, $current) vw(10, $current);
                    }
                }
            }
        }
    }
}